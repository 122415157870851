import { Component, Inject, OnInit ,ViewChild} from '@angular/core';
import { TouchSequence } from 'selenium-webdriver';
import { OrdersService } from 'src/app/services/orders.service';
import { LoginService } from 'src/app/services/login.service';
import { Select2OptionData } from 'ng-select2';
import { CustomersService } from 'src/app/services/customers.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {AsyncPipe} from '@angular/common';
import {MatAutocompleteModule, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.css']
})
export class AllOrdersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public orders:any = [];
  public order: any;
  public order_detail: any;
  public user: any;
  public detail_user: any;
  public customers: Array<Select2OptionData> = [];
  public all_customers: any[] = [];
  public customer: any;
  public customer_id: any;
  public isDataLoaded = 0;
  public copy_permissions = 0;
  public date_default = new Date((new Date().getTime()));
  public date_start = this.datePipe.transform(this.date_default, 'yyyy-MM-dd');
  public date_end = this.datePipe.transform(this.date_default, 'yyyy-MM-dd');
  disabled = '';

  isValueChanged = false;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = ['id_pedido', 'fecha_alta', 'id_cliente', 'nombre_cliente', 'empleado', 'tu_precio', 'status', 'actions'];


  // customer_select = new FormControl('');
  // filteredOptions: Observable<string[]>;

  customer_select = new FormControl('');
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<any[]>;

  currentDate = new Date();
  constructor(
    private ordersService: OrdersService,
    private customersService:CustomersService,
    public pop_detail: MatDialog,
    private router: Router,
    private datePipe: DatePipe
  ) 
  {
    this.user = LoginService.get_user();
    this.detail_user = this.user["data"][0]
    
   }

  ngOnInit() {
    Swal.fire({
      title: 'Cargando, obteniendo clientes',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.isDataLoaded = 1;
    this.get_customers();
    
  }

  private _filter(value: any): any {
    let filterValue;
    if(typeof(value) == 'string'){
      filterValue = value.toLowerCase();
    }else{
      filterValue = value['text'].toLowerCase();
    }
   

    return this.customers.filter(custom => custom.text.toLowerCase().includes(filterValue));
  }

  displayCustomerFn(customer: any): string {
    return customer ? customer.text : '';
  }

  onSelectOption(event: MatAutocompleteSelectedEvent) {
    // Realiza las acciones que deseas realizar con la opción seleccionada
    console.log('Opción seleccionada:', event.option.value);
  }


  errorAlert(title: string, message: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: message,
      confirmButtonText: 'Aceptar',
    })
  }




  get_orders(customer_id:any) {
    Swal.fire({
      title: 'Cargando, obteniendo ordenes',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    // if ( $.fn.dataTable.isDataTable( '#orders-index' ) ) {
    //   $('#orders-index').DataTable().destroy();
    // }
   
    let params = {
      "idPedido" : "",
      "fechaInicio" : this.date_start || null,
      "fechaFin" : this.date_end || null,
      "idCliente" : customer_id,
      "paginaInicio" : 0,
      "paginaFin" : 1000,
      "idUsuario" : this.detail_user.id_usuario,
      "idTipoUsuario": this.detail_user.id_tipo
    }
    
    this.ordersService.getAll(params).subscribe(data => {
    
      this.orders = new MatTableDataSource(data["data"]);
      this.orders.paginator = this.paginator;

      //this.orders = [ {"IDPedido": "299", "IDCliente": "10088", "NombreCliente": "RODRIGO MARTIN RODRIGUEZ", "IDUsuario": "61","AMostrador":"N","Calle":"CALLE 27 SN x 22 y 24","Ciudad": "KANASIN","CodigoPostal": "97310","Colonia": "COL. CUAUHTEMOC","CostoEnvio": "0.000000000","Empleado": "Samuel Aguilar Pedraza","Estatus": "2","FechaAlta": "2022-01-06 21:35:41","IDCanalVenta": "01","IDCliente": "10088","IDEmpleado": "397","IDEstado": "YUC","IDMoneda": "MXN","IDOrganizacionVentas": "1010300","IDPais": "MX","IDPedido": "299","IDPrioridadEntrega": "1","IDTransportista": "108","IDUsuario": "61","Municipio": "","NombreCliente": "RODRIGO MARTIN RODRIGUEZ","NombreUsuario": "Samuel Aguilar Pedraza","Numero": "","NumeroInterior": "","Observaciones": null,"OrdenCompra": null,"ProntoPago": "7.000000000","Seguro": "2.000000000","Telefono1": "+52 (01) 999-988 15 79","Telefono2": "","Transportista": "SR ESTAFETA","TuPrecioCostoEnvio": "0.000000000","TuPrecioIva": "64.039680000","TuPrecioSeguro": "7.848000000","TuPrecioSubtotal": "400.248000000","TuPrecioSuma": "392.400000000","TuPrecioTotal": "464.287680000"}]
      this.isDataLoaded = 2;
    //   window.setTimeout( function (){ 
    //     $('#orders-index').DataTable(
    //     {
    //       pagingType: 'full_numbers',
    //       destroy: true,
    //       language: {
    //         "processing": "Procesando",
    //         "lengthMenu": "Mostrar _MENU_ registros",
    //         "emptyTable": "Ningún dato disponible en esta tabla",
    //         "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    //         "infoEmpty": "Mostrando 0 registros del 0 de entradas",
    //         "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    //         "search": "Buscar",
    //         "infoPostFix": "",
    //         "url": "",
    //         "zeroRecords": "No se encontraron resultados",
    //         "loadingRecords": "Cargando...",
    //         "paginate":{
    //           "first": "Primero",
    //           "last": "Último",
    //           "next": "Siguiente",
    //           "previous": "Anterior"
    //         },
    //         "aria": {
    //           "sortAscending": ": Activar para ordenar la columna de manera ascendente",
    //           "sortDescending": ": Activar para ordenar la columna de manera descendente"
    //         }
    
      
            
    //       },
    //     }
    //   )
      
    // }, 1000);
    Swal.close()
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    });
  }


  get_customers(){
    this.customersService.getAll(this.detail_user.id_usuario, this.detail_user.id_tipo).subscribe(data => {
      this.all_customers = data["data"];
      
      LoginService.set_all_customers(this.all_customers)
      let arrCustomers = [];

      for (let i = 0; i < this.all_customers.length; i++) {
        arrCustomers.push({id: this.all_customers[i].IDCliente.toString(), text: this.all_customers[i].Nombre+" - "+this.all_customers[i].IDCliente})
      }
      // console.log(arrCustomers)
      this.customers = arrCustomers;

      this.filteredOptions = this.customer_select.valueChanges.pipe(
        startWith(''),
        map((custom:any) => (custom ? this._filter(custom) : this.customers.slice())),
        );
       
      Swal.close()
    });   
  }


  get_customer(id: any) {
    this.isValueChanged = false;
    // console.log(id)
    
    if (!this.isValueChanged) {
      this.isValueChanged = true;
      // console.log('getCustomer')
      // console.log(id.option.value)
      this.customer_id = id.option.value['id']; //Add new value
      // this.customer_select.setValue(this.customers.find(custom => custom.id = this.customer_id).text);
      // if(id){
      //   this.customer_id = id;
      // }
      // else {
      //   this.customer_id = this.all_customers[0].IDCliente;
      // }
  
      // this.get_orders(this.customer_id);
      OrdersService.set_customer_id(this.customer_id)
      this.check_permissions();
      this.get_orders(this.customer_id)
      // this.customersService.get(this.customer_id, this.detail_user.id_usuario, this.detail_user.id_tipo).subscribe(data => {
      //   this.customer = data["data"]["cliente"];
      //   console.log(this.customer)
      //   OrdersService.set_customer_order(this.customer)
      //   this.check_permissions();
      //   this.isValueChanged = false;

        
      //   // Swal.close()
      // });
      // this.isValueChanged = false;
    }
    // this.isValueChanged = false;
    // Swal.close()
  }


  get_date_start(type: string, event: MatDatepickerInputEvent<Date>) {
    this.isValueChanged = false;
    // console.log(event.value)
    if (!this.isValueChanged) {
      // console.log('se esta efecutando fecha inicio')
      this.isValueChanged = true;
      this.date_start = this.datePipe.transform(event.value, 'yyyy-MM-dd');
      if(this.date_start > this.date_end){
        this.errorAlert("Error", "La fecha de inicio no puede ser mayor a la fecha de fin");
        this.date_start = this.datePipe.transform(this.date_end, 'yyyy-MM-dd');
        //$("#mat-input-0").val(this.datePipe.transform(this.date_end, 'dd/MM/yyyy'))
      }
      if(this.customer_id){
        this.get_orders(this.customer_id);
      }
      
    }
    
  }

  get_date_end(type: string, event: MatDatepickerInputEvent<Date>) {
    this.isValueChanged = false;
    // console.log(event.value)
    if (!this.isValueChanged) {
      // console.log('se esta efecutando fecha fin')
      // console.log(event)
      this.isValueChanged = true;
       
      this.date_end = this.datePipe.transform(event.value, 'yyyy-MM-dd');
      if(this.date_end < this.date_start){
        this.errorAlert("Error", "La fecha de fin no puede ser menor a la fecha de inicio");
        this.date_end = this.datePipe.transform(this.date_start, 'yyyy-MM-dd');
        //$("#mat-input-1").val(this.datePipe.transform(this.date_start, 'dd/MM/yyyy'))
      }

      if(this.customer_id){
        this.get_orders(this.customer_id);
      }
     
    }
    console.log(this.date_start, this.date_end, this.customer_id)
    
  }

  show_detail(id_order:any) {
   
    Swal.fire({
      title: 'Cargando, obteniendo datos del cliente',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    this.customersService.get(this.customer_id, this.detail_user.id_usuario, this.detail_user.id_tipo).subscribe(data => {
      this.customer = data["data"]["cliente"];
      // console.log(this.customer)
      OrdersService.set_customer_order(this.customer)
      
      this.order = this.orders.filteredData.find((order:any) => order.IDPedido == id_order)
      // console.log(this.order)
      Swal.close()
      const dialogRef = this.pop_detail.open(OrderDetailComponent,{
        data: this.order
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log(`Dialog result: ${result}`);
      });
      
    });
    // if(this.orders.length >0 ) {
      
    // }
    
  }

  check_permissions() {
    if(['7','1','61'].includes(this.detail_user.id_usuario) && this.customer.IDJerarquiaCliente){
      this.copy_permissions = 1;
    }
    else {
      this.copy_permissions = 0;
    }
  }


  copy_order(id_order:any) {
    Swal.fire({  
      title: "Confirmación",  
      text: "¿Desea copiar este pedido a uno nuevo?",  
      icon: 'question',  
      showCancelButton: true,  
      confirmButtonText: 'Aceptar',  
      cancelButtonText: 'Cancelar'  
    }).then((result) => {  
      if (result.value) {  
        this.ordersService.get(id_order).subscribe(data => {
          this.order_detail = data["data"];
          OrdersService.set_products(this.order_detail)
          
          const dialogRef = this.pop_detail.open(OrderDetailComponent,{
            data: this.order_detail[0]
          });
    
          dialogRef.afterClosed().subscribe(result => {
            console.log(`Dialog result: ${result}`);
          });
       
        });  
      }  
    })  

    
  }


  download_order(id_order:any) {
    this.disabled = 'disabled';
    Swal.fire({
      title: 'Generando archivo',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    $(`.${id_order}`).prop("style","display:block;")

    let params = {
      "idPedido" : id_order,
      "idUsuario" : this.detail_user.id_usuario,
      "idTipoUsuario" : this.detail_user.id_tipo
    }
  
    this.ordersService.download_file(params).subscribe(data => {
      console.log(data)
      let file = new Blob([data], {type: 'application/pdf'} );
      let fileURL = URL.createObjectURL(file);
      Swal.close()
      window.open(fileURL);
      this.disabled = '';
      $(`.${id_order}`).prop("style","display:none;")
    });

  }
  onPageChange(event: any) {
    this.paginator.pageIndex = event.pageIndex;
    this.paginator.pageSize = event.pageSize;
    // this.updateDataSourcePaginator();
  }

  applyFilter(event: any): any {
    console.log(event.value);
    const filterValue = (event as HTMLInputElement).value;
    this.orders.filter = filterValue.trim().toLowerCase();
  }
}


@Component({
  selector: 'order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./all-orders.component.css']
})
export class OrderDetailComponent {
  public panelOpenState = false;
  public customers: Array<Select2OptionData> = [];
  public all_customers: any = [];
  public customer: any;
  public customer_id: any;
  public order_detail: any;

  constructor(
    private allOrdersComponent:AllOrdersComponent,
    private ordersService: OrdersService,
    private router: Router,
    // private customersService:CustomersService,
    @Inject(MAT_DIALOG_DATA) public data:any,
  //   public data: {
  //     IDPedido:"",
  //     IDCliente:"",
  //     NombreCliente:"",
  //     IDUsuario:"",
  //     IDOrganizacionVentas:"",
  //     IDMoneda:"",
  //     ProntoPago:"",
  //     Seguro:"",
  //     CostoEnvio:"",
  //     IDTransportista:"",
  //     Transportista:"",
  //     IDPais:"",
  //     IDEstado:"",
  //     Municipio:"",
  //     Colonia:"",
  //     Calle:"",
  //     Numero:"",
  //     CodigoPostal:"",
  //     Telefono1:"",
  //     Telefono2:"",
  //     TuPrecioSuma:"",
  //     TuPrecioSeguro:"",
  //     TuPrecioCostoEnvio:"",
  //     TuPrecioSubtotal:"",
  //     TuPrecioIva:"",
  //     TuPrecioTotal:"",
  //     OrdenCompra:"",
  //     Observaciones:"",
  //     IDEmpleado:"",
  //     Empleado:"",
  //     IDPrioridadEntrega:"",
  //     IDCanalVenta:"",
  //     AMostrador:"",
  //     Estatus:"",
  //     FechaAlta:"",

  //     Existencia: ""
    
  // }
  
  ) 
  
  {}

  ngOnInit() {

    
    
    this.all_customers = LoginService.get_all_customers();
    this.customer_id = OrdersService.get_customer_id();
    this.customer = OrdersService.get_customer_order();

    let arrCustomers = [];

    for (let i = 0; i < this.all_customers.length; i++) {
      arrCustomers.push({id: this.all_customers[i].IDCliente.toString(), text: this.all_customers[i].Nombre+" - "+this.all_customers[i].IDCliente})
    }
    this.customers = arrCustomers;
    
   
  }


  get_order_detail(id: any, id_order:any ){
    this.customer_id = id
    this.ordersService.get(id_order).subscribe(data => {
      this.order_detail = data["data"];
      OrdersService.set_products(this.order_detail)
    });
    
  }


  send_copy_data(customer_id:any) {
    this.router.navigate(['./new-order/',customer_id]);
  }

  
  
}
