import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  
  public user: any;
  public token: string;
  public detail_user: any;
  public step = 1;
  mobileQuery: MediaQueryList;

  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);

  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher,
    private loginService: LoginService,
    private router: Router
  ) 
  {
    
   
    this.user = LoginService.get_user();
    this.detail_user = this.user["data"][0]
    this.token = this.user["access_token"]
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
   }

  ngOnInit(): void {
    //this.user_detail();
  }

  errorAlert(title: string,message: string){
    Swal.fire({
      icon: 'error',
      title: title,
      text: message,
      confirmButtonText: 'Aceptar',
    })    
  }


  successAlert(title: string,message: string){
    Swal.fire({
      icon: 'success',
      title: title,
      text: message,
      confirmButtonText: 'Aceptar',
    })    
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  shouldRun = true;

  //shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
  
  setStep(index: number) {
    this.step = index;
  }

  // user_detail(){
  //   this.loginService.user_detail(this.user['id_tipo']).subscribe(
  //     data => {this.detail_user = data;
  //   },
  //     error => { alert("Hubo un problema al obtener la info del usuario") });
  // }


  logout() {
    this.loginService.logout(this.token).subscribe(
      data => {
      //alert(JSON.stringify(data["message"]));
      this.successAlert("",data["message"]);
      this.router.navigate(['./login']);

      localStorage.removeItem('jwt');
      localStorage.removeItem('user');
    },
      error => { this.errorAlert("Error","Ha ocurrido un error "+(error));
                  //alert("No se pudo cerrar la sesión") 
      });
      
  }

}
