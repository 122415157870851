import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { CustomersService } from 'src/app/services/customers.service';
import { LoginService } from 'src/app/services/login.service';
import { ProductsService } from 'src/app/services/products.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { isDevMode } from '@angular/core';
import { OrdersService } from 'src/app/services/orders.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { Input } from '@angular/core';
// import { BsModalService } from 'ngx-bootstrap/modal';
import * as Papa from 'papaparse';
import { ImageCarouselComponent } from 'src/app/image-carousel/image-carousel.component';
import { forkJoin } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css'],
})
export class NewOrderComponent implements OnInit {
  remainingTime: number = 0;
  interval: any;

  public isMinimunSale: boolean = false;
  public precioTotalPP: any = 0;
  public precioMinimoXCliente: any = 2000;
  public totalFaltantePrecioMin: any = 0;
  public mensajeErrorSeleccionTransportista: boolean = false;

  texto: string = '';
  textoOrdenCompra: string = '';
  contador: number = 0;
  contadorOrdenCompra: number = 0;
  limiteCaracteres: number = 250;
  limiteCaracteresOrdenCompra: number = 33;

  public activeSecondTab: boolean = false;

  public idTransportistaImporteMinimo: any;
  public idSedeImporteMinimo: any;

  @ViewChild('tableProducts', {
    static: false,
  })
  tableArticles: ElementRef<HTMLInputElement>;
  public activeSearchAdv: boolean = false;
  public articleSelected: number = 0;
  private maxIndexSelect: number = 0;
  dataSource = new MatTableDataSource<any>([]);

  //@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild(MatPaginator) set paginator(value: MatPaginator) {
    this.dataSource.paginator = value;
  }
  // @ViewChild(MatSort) set sort(value: MatSort) {
  //   this.dataSource.sort = value;
  // }
  //@ViewChild(MatSort) sort: MatSort;
  flagKey = 'bloq_paso2';
  flagValue: boolean = false;
  id_flag_validation: any;

  public isRouteBlock = false;

  public user: any;
  public detail_user: any;
  public NewOrderForm: FormGroup;
  public customers: Array<Select2OptionData> = [];
  public address_cust: Array<Select2OptionData> = [];
  public address_fact: Array<Select2OptionData> = [];
  public delivery_priorities: Array<Select2OptionData> = [];
  public tranportistas: Array<Select2OptionData> = [];
  public all_transportistas: any[] = [];
  public address_fact_detail: any[] = [];
  public address_entr_detail: any = '';
  public transporte: any = '';
  public customer: any;
  public customer_address: any = [];
  public customer_id: any;
  public products: any = [];
  public product: any;
  public last_product: any;
  public order: any;
  public observations: any;
  public params: any;
  public num_order: any;
  public obs_order: any;
  public bill = 'N';
  public panelOpenState = false;
  public rows: any[] = [];
  public temp_rows: any[] = [];
  public copy_rows: any[] = [];
  public updated_rows: any = [];
  public csv_rows: any[] = [];
  public orders: any = {};
  public sort_order: any[] = [];
  public names_sort_order: any[] = [];
  //public local_orders: any = new Map();
  public selected_default: any[] = ['Transporte Dapesa', 'Inmediata'];
  public address_default: any = '';
  public input_filter: any = '';
  public isDataLoaded: any = 0;
  public isVerified: any = 0;
  public isCustLoaded: any = 0;
  public AllCustLoaded: any = 0;
  public CsvLoaded: any = 0;
  public length: any;
  public pageSize: any;
  public pageNo: any;
  public paginaInicio: any = 0;
  public paginaFin: any = 25;
  public characters = /\./gi;
  public ready = false;
  public copy_permissions = 0;
  //public fileToUpload: File = null;
  dtOptions: DataTables.Settings = {};
  //dataSource: MatTableDataSource<any>;
  //dataArts: any = [];
  displayedColumns: string[] = [
    'Clave',
    'Descripción',
    'Unidad de medida',
    'Marca',
    'Terminología',
    'Clasificación',
    'Detalle',
    'Cerrar',
  ];
  disabled = '';
  process_order = '';
  public product_detail = null;
  public promotions: any = [];

  public finalWindow = false;

  @ViewChild('uploadFileCsv', { static: false }) uploadFileCsv: ElementRef;
  @ViewChild('searchField') searchInput: ElementRef;

  constructor(
    private fb: FormBuilder,
    private productsService: ProductsService,
    private customersService: CustomersService,
    private ordersService: OrdersService,
    private route_params: ActivatedRoute,
    private router: Router,
    public pop_detail: MatDialog // private modalService: BsModalService
  ) {
    //Conseguimos Datos de usuario
    this.user = LoginService.get_user();
    this.detail_user = this.user['data'][0];
    this.panelOpenState = false;

    this.route_params.params.subscribe((params) => {
      this.params = params;
      LoginService.set_customer(this.params['id']);
    });

    this.NewOrderForm = fb.group({
      num_order: [null, []],
      obs_order: [null, []],
    });

    window.addEventListener('storage', (event) => {
      if (event.key === 'reload-windows' && event.newValue) {
        this.reloadPage();
      }
    });
    //this.dataSource = new MatTableDataSource();
  }

  triggerReload() {
    localStorage.setItem('reload-windows', `${Date.now()}`);
  }

  private reloadPage() {
    this.router.navigate([this.router.url]).then(() => {
      window.location.reload();
    });
  }

  openSliderPromotions(imgs: any) {
    const dialogRef = this.pop_detail.open(ImageCarouselComponent, {
      data: {
        images: imgs,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  contarCaracteres() {
    this.contador = this.texto.length;
  }

  contarCaracteresOrdenCompra() {
    this.contadorOrdenCompra = this.textoOrdenCompra.length;
  }

  openUploadCsv() {
    this.uploadFileCsv.nativeElement.click();
  }

  pressEscProductOrder() {
    document.getElementById('first-filter').focus();
    this.clean_searcher();
    this.activeSearchAdv = false;
  }
  pressEnterProductOrder(idRow: string) {
    document.getElementById(idRow).blur();
    document.getElementById('first-filter').focus();
    this.activeSearchAdv = false;
  }

  setFocusFirstFilter() {
    let maxPromise = 100;
    const promise = setInterval(() => {
      maxPromise--;
      if (this.customer != undefined || this.customer != null) {
        setTimeout(() => {
          try {
            document.getElementById('first-filter').focus();
          } catch (error) {
            console.warn('input: first-filter', error);
          }
        }, 500);
        clearInterval(promise);
      }
      if (maxPromise == -1) {
        clearInterval(promise);
      }
    }, 500);
  }

  //Atajos de teclado
  @HostListener('window:keydown', ['$event'])
  keyEvent(event: KeyboardEvent) {
    //console.log(event.keyCode)
    switch (event.keyCode) {
      //tecla esc
      case 27:
        if (
          Number(document.activeElement.id) == this.articleSelected ||
          document.activeElement.id == 'input-search-adv'
        ) {
          this.clean_searcher();
          this.activeSearchAdv = false;
        }
        document.getElementById('first-filter').focus();
        break;
      //tecla enter
      case 13:
        //Si la bandera de busqueda avanzada esta activda y el focus esta en el input de simulacion
        //entonces es valido agregar al grid
        /*
        console.log('flag: ',this.activeSearchAdv)
        console.log('id row: ',Number(document.activeElement.id))
        console.log('selected: ', this.articleSelected)
        console.log('length: ',this.dataSource.data.length)
        */
        if (
          this.activeSearchAdv &&
          Number(document.activeElement.id) == this.articleSelected &&
          this.dataSource.data.length > 0
        ) {
          this.dataSource.data.forEach((element) => {
            if (element['indexTemp'] == this.articleSelected) {
              this.activeSearchAdv = false;

              this.get_product(element.clave, 1, -1, true);
              this.clean_searcher();
              return;
            }
          });
        }
        break;
      //tecla tabulador
      case 9:
        //console.log('activeSearchAdv: ',this.activeSearchAdv, ' class: ', document.activeElement.className);
        if (
          this.activeSearchAdv == false ||
          document.activeElement.className == 'input-fake-focus'
        ) {
          this.activeSearchAdv = true;
          //Ponemos el foco en el campo busqueda avanzada
          try {
            document.getElementById('input-search-adv').focus();
          } catch (error) {
            console.warn('input: input-search-adv ', error);
          }
          event.preventDefault();
        } else if (document.activeElement.id == 'input-search-adv') {
          //document.getElementById("btn-csv-download").focus()
          this.clean_searcher();
          document.getElementById('input-search-adv').blur();
          //SetTimeOut para solucionar la omision del seteo del focus en un hipervinculo
          setTimeout(() => {
            document.getElementById('btn-csv-download').focus();
          }, 500);
        }
        break;
      //tecla f4
      case 115:
        if (this.dataSource.data.length > 0) {
          this.dataSource.data.forEach((element) => {
            if (element['indexTemp'] == this.articleSelected) {
              this.show_detail2(element.clave);
              event.preventDefault();
              return;
            }
          });
        }
        break;
      case 38:
        //Tecla fleca arriba
        if (this.articleSelected > 0) {
          this.articleSelected--;
          if (
            this.articleSelected <
            this.dataSource.paginator.pageSize *
              this.dataSource.paginator.pageIndex
          ) {
            this.dataSource.paginator.previousPage();
          }
          event.preventDefault();
        }
        break;
      case 40:
        //Tecla fleca abajo
        if (this.articleSelected < this.maxIndexSelect - 1) {
          this.articleSelected++;
          if (
            this.articleSelected ==
            (this.dataSource.paginator.pageIndex *
              this.dataSource.paginator.pageSize ==
            0
              ? this.dataSource.paginator.pageSize
              : (1 + this.dataSource.paginator.pageIndex) *
                this.dataSource.paginator.pageSize)
          ) {
            this.dataSource.paginator.nextPage();
          }
          event.preventDefault();
        }
        break;
      case 39:
        //tecla flecha derecha
        if (
          document.activeElement.id != 'input-search-adv' &&
          document.activeElement.id != 'first-filter'
        ) {
          this.dataSource.paginator.nextPage();
          if (this.dataSource.paginator.pageIndex == 0) {
            this.articleSelected = 0;
          } else {
            this.articleSelected =
              this.dataSource.paginator.pageSize *
              this.dataSource.paginator.pageIndex;
          }
          event.preventDefault();
        }
        break;
      case 37:
        //tecla flecha izquierda
        if (
          document.activeElement.id != 'input-search-adv' &&
          document.activeElement.id != 'first-filter'
        ) {
          this.dataSource.paginator.previousPage();
          if (this.dataSource.paginator.pageIndex == 0) {
            this.articleSelected = 0;
          } else {
            this.articleSelected =
              this.dataSource.paginator.pageSize *
              this.dataSource.paginator.pageIndex;
          }
          event.preventDefault();
        }
        break;
    }
    //console.log(this.articleSelected)
    if (
      event.keyCode == 37 ||
      event.keyCode == 38 ||
      event.keyCode == 39 ||
      event.keyCode == 40
    ) {
      //Funcional pero depende de la sincronia
      this.activeSearchAdv = true;
      const promise = setInterval(() => {
        if (document.getElementById(this.articleSelected + '')) {
          document.getElementById('input-search-adv').blur();
          document.getElementById(this.articleSelected + '').focus();
          clearInterval(promise);
        }
      }, 10);
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: Event) {
    // Reinicia la bandera al cerrar el navegador
    localStorage.setItem(this.flagKey, 'false');
  }

  @HostListener('window:storage', ['$event'])
  storageEventListener(event: StorageEvent) {
    if (event.key === this.flagKey) {
      this.flagValue = this.getFlag();
    }
  }

  ngOnInit() {
    this.id_flag_validation = uuidv4();
    localStorage.setItem(this.flagKey, 'false');
    this.get_customers();
    this.get_fixed_catalogs(); //Prioridades de entrega y transportistas
    this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
    this.bill = 'N'; //Factura MOS
    this.rows = []; //lineas de la tabla reiniciada
    OrdersService.set_rows(this.rows);
    this.copy_rows = [];

    this.pageSize = 5;
    this.pageNo = 0;
  }

  setFlag(value: boolean) {
    localStorage.setItem(this.flagKey, value.toString());
    this.flagValue = value;
  }

  getFlag(): boolean {
    return localStorage.getItem(this.flagKey) === 'true';
  }

  errorAlert(title: string, message: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: message,
      confirmButtonText: 'Aceptar',
    });
  }

  warningAlert(title: string, message: string) {
    Swal.fire({
      icon: 'warning',
      title: title,
      text: message,
      confirmButtonText: 'Aceptar',
    });
  }

  successAlert(title: string, message: string, html: boolean = false) {
    let obj: SweetAlertOptions = {
      icon: 'success',
      title: title,
      confirmButtonText: 'Aceptar',
      timer: 5000,
    };
    html ? (obj.html = message) : (obj.text = message);
    Swal.fire(obj);
  }

  get_customers() {
    this.AllCustLoaded = 1;
    this.customersService
      .getAll(this.detail_user.id_usuario, this.detail_user.id_tipo)
      .subscribe((data) => {
        let all_customers = data['data'];
        let arrCustomers = [];
        for (let i = 0; i < all_customers.length; i++) {
          arrCustomers.push({
            id: all_customers[i].IDCliente.toString(),
            text: all_customers[i].Nombre + ' - ' + all_customers[i].IDCliente,
          });
        }

        //Se llena el array de clientes a los clientes
        this.customers = arrCustomers;
        //Bandera de loader
        this.AllCustLoaded = 2;
      });
  }

  get_customer(id: any) {
    this.clean_data();
    //Se limpian valores para lleanar cliente

    if (id) {
      LoginService.set_customer(id);
      this.router.navigate(['./new-order/', id]);

      OrdersService.set_products([]);
      this.copy_rows = [];
    } else {
      //Por la ejecución del select se coloca el customer del parametro del URL
      if (this.params['id']) {
        LoginService.set_customer(this.params['id']);
        $('#select2-customer select').val(this.params['id']).trigger('change');
      } else {
        const first_customer_id: any = this.customers[0].id;
        $('#select2-customer select').val(first_customer_id).trigger('change');
        this.copy_rows = OrdersService.get_products(); //Uso copy_rows
      }

      this.address_fact_detail = [];
      this.customer_address = []; // Ya estan en clean data;
    }

    this.customer_id = LoginService.get_customer();

    // this.customer_address = []
    if (this.customer_id && id) {
      this.get_observations(this.customer_id); //Obtenemos observaciones del cliente

      // console.log(this.detail_user);
      const objectValidate = {
        id_tlmk: this.detail_user.id_usuario,
        id_cliente: this.customer_id,
      };
      const currentValidate = JSON.parse(
        localStorage.getItem(
          `${objectValidate.id_cliente}${objectValidate.id_tlmk}`
        )
      );
      console.log(currentValidate);
      if (currentValidate) {
        //Validamos que se actualice la bandera
        const objectValidate = {
          id_tlmk: this.detail_user.id_usuario,
          id_cliente: this.customer_id,
          id_bandera: this.id_flag_validation,
        };
        this.customersService
          .getValidacionCliente(objectValidate)
          .subscribe((res) => {
            if (res.responseValidacion.length > 0) {
              this.customersService
                .updateValidacionCliente(objectValidate)
                .subscribe((res) => {
                  console.log(res);
                  localStorage.setItem(
                    `${objectValidate.id_cliente}${objectValidate.id_tlmk}`,
                    JSON.stringify(res.data)
                  );
                });
            } else {
              this.customersService
                .postValidacionCliente(objectValidate)
                .subscribe((res) => {
                  console.log(res);
                  localStorage.setItem(
                    `${objectValidate.id_cliente}${objectValidate.id_tlmk}`,
                    JSON.stringify(res.data)
                  );
                });
            }
          });
      } else {
        console.log('creando bandera');
        const objectValidateExist = {
          id_tlmk: this.detail_user.id_usuario,
          id_cliente: this.customer_id,
          id_bandera: this.id_flag_validation,
        };
        this.customersService
          .getValidacionCliente(objectValidateExist)
          .subscribe((res) => {
            console.log(res);
            const objectValidate = {
              id_tlmk: this.detail_user.id_usuario,
              id_cliente: this.customer_id,
              id_bandera: this.id_flag_validation,
            };
            if (res.responseValidacion.length > 0) {
              //Existe registro cliente - usuario
              localStorage.setItem(
                `${res.responseValidacion[0].ID_CLIENTE}${res.responseValidacion[0].ID_TLMK}`,
                JSON.stringify(res.responseValidacion[0])
              );
            } else {
              //No existe registro cliente  - usuario

              this.customersService
                .postValidacionCliente(objectValidate)
                .subscribe((res) => {
                  console.log(res);
                  localStorage.setItem(
                    `${objectValidate.id_cliente}${objectValidate.id_tlmk}`,
                    JSON.stringify(res.data)
                  );
                });
            }
          });
      }

      this.customersService
        .get(
          this.customer_id,
          this.detail_user.id_usuario,
          this.detail_user.id_tipo
        )
        .subscribe((data) => {
          const procedimientoSiguienteImporteMinimo = () => {
            this.isCustLoaded = 1;

            this.customer_address = data['data']['direcciones'];
            this.customer['PP'] = parseFloat(this.customer.PP);
            this.update_transporte(this.customer.IDTransportista); //Se calcula el total de nuevo aca
            LoginService.set_customer_detail(this.customer);
            this.get_color_status(this.customer.IDEstatus);
            this.check_local(this.customer_id);
            this.get_disabled(this.rows);
            this.check_permissions();
            //Linea comentada anteriormente

            this.get_total(this.rows);
            let arr_address = [];
            let arr_address_fact = [];

            for (let i = 0; i < data['data']['direcciones'].length; i++) {
              let address = data['data']['direcciones'][i];

              if (address.esPrincipal == 'S') {
                arr_address_fact.push({
                  id: address.consecutivo.toString(),
                  text: address.nombre,
                });
                arr_address.push({
                  id: address.consecutivo.toString(),
                  text: address.nombre,
                });

                //this.full_addres_detail("fiscal", address)
                //this.full_addres_detail("entrega", address)
                const existe = this.address_fact_detail.find(
                  (add) => add.cli_clave == address.cli_clave
                );
                if (!existe) {
                  this.address_fact_detail.push(address);
                  this.address_default = address;
                }
              } else {
                arr_address.push({
                  id: address.consecutivo.toString(),
                  text: address.nombre,
                });

                //this.full_addres_detail("entrega", address)
              }
            }

            this.address_cust = arr_address;
            this.address_fact = arr_address_fact;

            this.router.navigate(['./new-order/', this.customer_id]);
          };

          //Conseguir bloqueos y cambiar a inactivo
          this.customer = data['data']['cliente'];
          this.isRouteBlock = false;
          this.customersService.getBloqueosRutas().subscribe((res) => {
            res.response.forEach((route) => {
              if (this.customer.Ruta == route.CLAVE) {
                this.isRouteBlock = true;
              }
            });
          });
          this.customersService
            .getBloqueoCliente(this.customer['IDCliente'])
            .subscribe((res) => {
              const bloqueoEntrega = res['response'][0].MotivoBloqueoEntrega;
              const bloqueoPedido = res['response'][0].MotivoBloqueoPedido;

              if (bloqueoEntrega || bloqueoPedido)
                this.customer['Estatus'] = 'Inactivo';
            });
          //Metodo de importe minimo
          this.idSedeImporteMinimo = this.customer.IDSede;
          this.idTransportistaImporteMinimo = this.customer.IDTransportista;

          if (this.idTransportistaImporteMinimo) {
            this.productsService
              .getPrecioMinimo(
                this.idSedeImporteMinimo,
                this.idTransportistaImporteMinimo
              )
              .subscribe(
                (data) => {
                  const importeMinimoCustomer =
                    data['data']['precio_minimo'][0].ImporteMinimo;

                  this.precioMinimoXCliente = importeMinimoCustomer;
                  this.totalFaltantePrecioMin = importeMinimoCustomer;
                  procedimientoSiguienteImporteMinimo();
                },
                (error) => {
                  console.error('Error en la respuesta del servidor:', error); // Imprime el mensaje de error en la consola
                  // ... manejo del error en tu aplicación ...
                }
              );
          } else {
            this.totalFaltantePrecioMin = this.precioMinimoXCliente;
            procedimientoSiguienteImporteMinimo();
            Swal.fire({
              title: 'Transportista no encontrado',
              text: 'El cliente no tiene registrado el transportista',
            });

            this.mensajeErrorSeleccionTransportista = true;
          }
        });
    }

    // Swal.close()
  }

  get_products() {
    let that = this;
    this.get_color_status(this.customer.IDEstatus, true);

    if (this.input_filter.length > 2) {
      this.isDataLoaded = 1;
      let cust_data = {
        search: this.input_filter,
        searchOriginal: this.input_filter,
        idOrganizacionVentas: this.customer.IDOrganizacionVentas,
        idArea: this.customer.IDSede,
        idMoneda: this.customer.IDMoneda,
        idClasificacion: '',
        idMarca: '',
        idFamilia: '',
        idProducto: '',
        idCliente: this.customer.IDCliente,
        idJerarquiaCliente: this.customer.IDJerarquiaCliente,
        idCanalDistribucion: this.customer.IDCanalDistribucion,
        paginaInicio: '0',
        paginaFin: '100',
      };

      this.productsService.getAll(cust_data).subscribe(
        (data) => {
          this.products = data['data'];
          const articulos = this.products.articulos;
          //Creamos método de obtener promocionales
          const pMaterialesArray = [];
          articulos.forEach((product: any, index: any) => {
            pMaterialesArray.push({
              Posicion: index,
              IDMaterial: product.clave,
              IDCategoria: product.IDCategoriaProducto,
              Marca: product.marca,
            });
          });

          // Descomentar promocionales
          const params_to_promocionales = {
            pMateriales: pMaterialesArray,
            pIDOrganizacionVentas: this.customer.IDOrganizacionVentas,
            pIDCanalDistribucion: this.customer.IDCanalDistribucion,
            pIDArea: this.customer.IDSede,
          };
          this.productsService
            .get_promocionales(params_to_promocionales)
            .subscribe((res) => {
              this.products.articulos.forEach((product) => {
                res.body.forEach((promo) => {
                  if (product.clave === promo.IdMaterial) {
                    product['promocion'] = true;
                    product['banner'] = promo.Banner;
                  }
                });
              });
              this.promotions = this.products.articulos;

              nextStage();
            });

          const nextStage = () => {
            let indexTemp = 0;
            this.products['articulos'].map((item) => {
              item['indexTemp'] = indexTemp;
              indexTemp++;
            });
            this.articleSelected = 0;
            this.maxIndexSelect = this.products['articulos'].length;

            //this.dataSource = new MatTableDataSource(this.products);
            this.dataSource.data = this.products['articulos'];
            this.isDataLoaded = 2;
            this.length = Number(this.products['total']);

            this.searchChange();

            //Funcional pero depende de la sincronia
            const table = setInterval(() => {
              if (
                document.getElementById('products-filter') &&
                this.length > 0
              ) {
                document.getElementById('first-filter').blur();
                document.getElementById('0').focus();
                clearInterval(table);
              }
            }, 500);
            if (this.products['articulos'].length == 0) {
              this.warningAlert('Aviso', 'No se encontrarón coincidencias');
              this.isDataLoaded = 0;
            }
          };

          // nextStage()
        },
        (error) => {
          this.errorAlert(
            'Error',
            'Ha ocurrido un error ' + JSON.stringify(error)
          );
          this.isDataLoaded = 0;
        }
      );
    } else {
      this.warningAlert(
        'Validación',
        'Por favor incluye al menos 3 letras para optimizar su búsqueda'
      );
    }
  }

  get_product_validate(orders) {
    // Swal.fire({
    //   title: 'Validando Porductos',
    //   text: 'Por favor, espera...',
    //   allowOutsideClick: false,
    //   showCancelButton: false,
    //   showConfirmButton: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    const materialsToCart = [];
    this.product_detail = null;
    const articulosCart = [];

    const claves = orders.map((ord) => ord.IDMaterial);

    if (claves.length) {
      const params: any = {
        idCliente: this.customer.IDCliente,
        idMaterial: claves.toString(),
        idMoneda: this.customer.IDMoneda,
        idOrganizacionVentas: this.customer.IDOrganizacionVentas,
        idArea: this.customer.IDSede,
        idJerarquiaCliente: this.customer.IDJerarquiaCliente,
        idCanalDistribucion: this.customer.IDCanalDistribucion,
        //"idPosicion": `${pos}`,
      };

      this.productsService.get_all_detail(params).subscribe((res) => {
        const articulos = res.data.articulos;
        const unidades = res.data.unidades;

        const requestsToPrice = [];

        const pMaterialesArray = [];
        articulos.forEach((art: any, index: any) => {
          const newRequest: any = {};

          if (unidades?.[art.clave]?.[0].length) {
            // const unidades_format = unidades[art.clave][0]
            const array_unidades = [];

            unidades[art.clave].forEach((art) => {
              const measurement_units_format = {
                IDUnidadMedida: art[0],
                UnidadMedida: art[1],
                Cantidad: art[2],
                CantidadConversion: art[3],
              };
              array_unidades.push(measurement_units_format);
            });

            art['measurement_units'] = array_unidades;
          } else {
            art['measurement_units'] = [];
          }

          pMaterialesArray.push({
            Posicion: index,
            IDMaterial: art.clave,
            IDCategoria: art.IDCategoriaProducto,
            Marca: art.marca,
          });

          // detalleArt["measurement_units"]  falta tema con unidades
          const productoEncontradoLocal = orders.find(
            (item) => item.IDMaterial === art.clave
          );
          art['iva'] = parseFloat(art.valor_iva);
          if (!productoEncontradoLocal) {
            art['request_amount'] = Number(art.multiplo_venta);
          } else {
            art['request_amount'] = parseInt(
              productoEncontradoLocal['Cantidad']
            ).toFixed();
          }
          // art["request_amount"] = parseInt(productoEncontradoLocal["Cantidad"]).toFixed();
          art['multiploVenta'] = Number(art.multiplo_venta);
          art['PP'] = this.customer.PP;
          art['idMarca'] = art.marca;
          art['idMaterial'] = art.clave;
          art['mostrar'] = '2';
          art['precioLista'] = art.precio;
          art['descuentoComercial'] = parseFloat(art.oculdesc);

          newRequest['cantidadPiezas'] = `${art['total_pzas']}`;
          art['cantidadPiezas'] = `${art['total_pzas']}`;

          art = this.um_convertion(art, art.unidad_medida_clave);

          newRequest['cantidadPiezas'] = `${art['total_pzas']}`;
          art['cantidadPiezas'] = `${art['total_pzas']}`;
          // console.log(art)
          let pos = index;
          // request_material["idPosicion"] = `${pos}`;
          newRequest['idPosicion'] = `${pos}`;
          newRequest['idMaterial'] = `${art['clave']}`;

          newRequest['idMarca'] = art.marca;
          newRequest['mostrar'] = '2';
          newRequest['IdUni'] = `${art['unidad_medida_clave']}`;

          const existe = articulosCart.some((item) => item.clave === art.clave);
          if (!existe) {
            articulosCart.push(art);

            requestsToPrice.push(newRequest);
          } else {
            console.log('-');
          }

          // this.update_price(request_material, false, -1, true)

          this.get_inventory(art);

          // materialsToCart.push(art);
          // art = this.um_convertion(art, art.unidad_medida_clave)
        });

        // Descomentar promocionales
        const params_to_promocionales = {
          pMateriales: pMaterialesArray,
          pIDOrganizacionVentas: this.customer.IDOrganizacionVentas,
          pIDCanalDistribucion: this.customer.IDCanalDistribucion,
          pIDArea: this.customer.IDSede,
        };
        this.productsService
          .get_promocionales(params_to_promocionales)
          .subscribe((res) => {
            articulos.forEach((product: any) => {
              res.body.forEach((promo: any) => {
                if (product.clave === promo.IdMaterial) {
                  product['promocion'] = true;
                  product['banner'] = promo.Banner;
                }
              });
            });
            // this.promotionsLocal = this.products.articulos;
          });
        const request_price = {
          requests_material: requestsToPrice,
          params: params,
        };
        // console.log(request_price)

        this.productsService
          .price_bloque(request_price)
          .subscribe((res): any => {
            Swal.close();

            if (res['data'].length) {
              res['data'].forEach((precio: any) => {
                for (let i = 0; i <= articulosCart.length; i++) {
                  if (articulosCart[i]) {
                    if (
                      articulosCart[i]['idMaterial'] == precio['IDMaterial']
                    ) {
                      articulosCart[i]['standard_price'] = precio['Precio'];
                      articulosCart[i]['precioEstandar'] =
                        articulosCart[i]['standard_price'];
                      articulosCart[i]['standard_discount'] =
                        precio['Descuento'];
                      articulosCart[i]['descuentoEstandar'] = parseFloat(
                        precio['Descuento']
                      );
                      articulosCart[i]['standard_disc_porc'] =
                        parseFloat(precio['Descuento']) / 100;
                      this.price_operations(false, -1, true, articulosCart[i]);

                      materialsToCart.push(articulosCart[i]);
                    }
                  }
                }
              });
            } else {
              const precio = res['data'];

              for (let i = 0; i <= articulosCart.length; i++) {
                if (articulosCart[i]) {
                  if (articulosCart[i]['idMaterial'] == precio['IDMaterial']) {
                    articulosCart[i]['standard_price'] = precio['Precio'];
                    articulosCart[i]['precioEstandar'] =
                      articulosCart[i]['standard_price'];
                    articulosCart[i]['standard_discount'] = precio['Descuento'];
                    articulosCart[i]['descuentoEstandar'] = parseFloat(
                      precio['Descuento']
                    );
                    articulosCart[i]['standard_disc_porc'] =
                      parseFloat(precio['Descuento']) / 100;

                    this.price_operations(false, -1, true, articulosCart[i]);

                    materialsToCart.push(articulosCart[i]);
                  }
                }
              }
            }
            this.rows.push(...materialsToCart);
            // this.rows = materialsToCart;
            OrdersService.set_rows(this.rows);

            this.get_total(this.rows);
          });
      });
    } else {
      Swal.close();
    }
  }

  get_product(
    id: any,
    amount: any,
    index: any,
    add = false,
    calculate = false,
    detail = false,
    promocion?: any,
    banner?: any
  ) {
    //get_product(item.clave, $event, i, false, true)
    Swal.fire({
      title: 'Cargando',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    this.disabled = 'disabled';
    this.product = null;
    this.customer = LoginService.get_customer_detail();

    var body: any = {
      idCliente: this.customer.IDCliente,
      idMaterial: id,
      idMoneda: this.customer.IDMoneda,
      idOrganizacionVentas: this.customer.IDOrganizacionVentas,
      idArea: this.customer.IDSede,
      idJerarquiaCliente: this.customer.IDJerarquiaCliente,
      idCanalDistribucion: this.customer.IDCanalDistribucion,
      //"idPosicion":"10"
    };

    if (id) {
      this.productsService.get(body).subscribe(
        (data) => {
          Swal.close();
          // console.log(add)
          //this.product = data["data"][0];
          this.product = data['data']['articulo'][0];
          // console.log(this.product);
          // console.log(this.rows)
          let existe = this.rows.find(
            (item) => item.clave === this.product.clave
          );
          // console.log(existe)
          this.product['measurement_units'] = data['data']['unidades'];
          if (!existe) {
            this.product['request_amount'] = Number(
              this.product.multiplo_venta
            );
          } else {
            this.product['request_amount'] = existe['request_amount'];
          }

          this.product['multiploVenta'] = Number(this.product.multiplo_venta);
          this.product['PP'] = this.customer.PP;
          this.product['idMarca'] = this.product.marca;
          this.product['idMaterial'] = this.product.clave;
          this.product['mostrar'] = '2';
          //this.product["idPosicion"] = 10;
          this.product['precioLista'] = this.product.precio;
          this.product['descuentoComercial'] = parseFloat(
            this.product.oculdesc
          );
          this.product['iva'] = parseFloat(this.product.valor_iva);
          this.product = this.um_convertion(
            this.product,
            this.product.unidad_medida_clave
          );

          body['idMarca'] = this.product.marca;
          body['cantidadPiezas'] = `${this.product['total_pzas']}`;
          this.product['cantidadPiezas'] = `${this.product['total_pzas']}`;
          body['mostrar'] = '2';
          // console.log(this.product)
          body['IdUni'] = `${this.product['unidad_medida_clave']}`;

          // console.log(body)
          //this.update_price(body, false, index)

          // Descomentar promocionales
          this.promotions.forEach((prod: any) => {
            if (this.product['clave'] == prod.clave) {
              this.product['promocion'] = prod.promocion;
              this.product['banner'] = prod.banner;
            }
          });

          if (add == true) {
            let pos = (this.rows.length + 1) * 10;
            body['idPosicion'] = `${pos}`;
            this.update_price(body, true, index, detail);
            this.orders[this.customer.IDCliente] = this.rows;
            this.searchInput?.nativeElement?.focus();
            // this.add_row(this.product, index);
          }
          if (add == false && calculate == false) {
            // console.log('calculate es false y add false')

            this.show_detail(index);
          }
          if (calculate == true) {
            // console.log('calculate true')
            let pos = (index + 1) * 10;
            body['idPosicion'] = `${pos}`;
            if (
              amount.target.value.includes('.') ||
              parseInt(amount.target.value) <
                Number(this.product.multiplo_venta) ||
              isNaN(parseInt(amount.target.value))
            ) {
              this.warningAlert(
                'Validación',
                `Cantidad minima permitida ${this.product.multiplo_venta}`
              );

              $(
                `#product_clave_${this.product.claveConversion} .amount input`
              ).val(Number(this.product.request_amount));
            } else {
              this.product['request_amount'] = parseInt(amount.target.value);
            }
            this.product = this.um_convertion(
              this.product,
              this.product.unidad_medida_clave
            );
            body['cantidadPiezas'] = `${this.product['total_pzas']}`;
            this.product['cantidadPiezas'] = `${this.product['total_pzas']}`;

            this.update_price(body, false, index);
            this.get_inventory(this.product);

            this.searchInput.nativeElement.focus();
          }

          //Funcional pero depende de la sincronia
          const promise = setInterval(() => {
            if (
              document.getElementById('product_amount_' + this.product.clave)
            ) {
              const element = document.getElementById(
                'product_amount_' + this.product.clave
              ) as HTMLInputElement;
              element.focus();
              element.select();
              clearInterval(promise);

              this.disabled = '';
              // this.searchInput.nativeElement.focus();
              // Mostrar un mensaje de éxito
            }
          }, 500);
        },
        (err) => {
          Swal.close();
          this.disabled = '';
        }
      );
    }
  }

  get_observations(customer_id: any) {
    if (customer_id) {
      this.customersService.observations(customer_id).subscribe((data) => {
        if (data['data'].length > 0) {
          this.observations = data['data'][0]['Nota'];
        } else {
          this.observations = '';
        }
      });
    }
  }

  get_fixed_catalogs() {
    //Prioridades de entrega
    this.customersService.priorities().subscribe((data) => {
      let priorities = data['data'];
      let arr_priorities = [];

      for (let i = 0; i < priorities.length; i++) {
        arr_priorities.push({
          id: priorities[i].ID.toString(),
          text: priorities[i].Descripción,
        });

        this.delivery_priorities = arr_priorities;
      }
    });

    //Transportistas
    this.customersService.tranportistas().subscribe((data) => {
      this.all_transportistas = data['data'];
      let arr_tranportistas = [];

      for (let i = 0; i < this.all_transportistas.length; i++) {
        arr_tranportistas.push({
          id: this.all_transportistas[i].IDTransportista.toString(),
          text: this.all_transportistas[i].Descripcion,
        });

        this.tranportistas = arr_tranportistas;
      }
    });
  }

  get_color_status(id_status: any, show_alert = false) {
    $('#status_cust').removeClass('activo');
    $('#status_cust').removeClass('blocked');
    $('#status_cust').removeClass('inactivo');
    let blocked = ['Item1', 'Item3', 'Item4', 'Item5'];

    if (id_status == 'Item2') {
      $('#status_cust').addClass('activo');
    }
    if (id_status == 'Item6') {
      $('#status_cust').addClass('inactivo');
      if (show_alert == true) {
        this.warningAlert(
          'Atención',
          `El cliente ${this.customer.IDCliente} ${this.customer.Nombre} Tiene un estatus que impide procesar el pedido, favor de revisar con el área de Crédito y Cobranzas`
        );
      }
    }
    if (blocked.find((item) => item === id_status)) {
      $('#status_cust').addClass('blocked');
      if (show_alert == true) {
        this.warningAlert(
          'Atención',
          `El cliente ${this.customer.IDCliente} ${this.customer.Nombre} Esta bloqueado, favor de revisar con el área de Crédito y Cobranzas `
        );
      }
    }
  }

  // full_addres_detail(tipo:string, address:any) {
  //   if(tipo === "fiscal"){
  //     this.address_fact_detail.push({cli_clave: address.cli_clave, Calle: address.calle, Colonia: address.colonia, CP: address.cp, Telefono: address.telefono1, Estado: address.estado, Pais: address.pais, Ciudad: address.ciudad, consecutivo: address.consecutivo })
  //   }
  //   if(tipo === "entrega"){//entrega
  //     this.address_entr_detail.push({cli_clave: address.cli_clave, Calle: address.calle, Colonia: address.colonia, CP: address.cp, Telefono: address.telefono1, Estado: address.estado, Pais: address.pais, Ciudad: address.ciudad, consecutivo: address.consecutivo })
  //   }
  // }

  first_filter(filter: any) {
    this.activeSearchAdv = true;
    this.input_filter = filter.target.value;
    //this.searchChange();
    this.get_products();
  }

  searchChange(): void {
    $('.table-loaded').show();
    $('#first-filter').val('');
  }

  clean_searcher() {
    $('.table-loaded').hide();
    $('#first-filter').val('');
    $('.second_filter').val('');
    this.dataSource.filter = '';
    this.isDataLoaded = 0;
  }

  clean_data() {
    this.address_fact_detail = [];
    this.address_entr_detail = [];
    this.isDataLoaded = 0;
    OrdersService.set_rows([]);
    this.rows = [];
    this.copy_rows = [];

    $('#num_order').val('');
    $('#obs_order').val('');
    $('#first-filter').val('');
  }

  reset_rows() {
    Swal.fire({
      title: 'Confirmación',
      text: '¿Está seguro que desea borrar el pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.rows = [];
        OrdersService.set_rows(this.rows);
        this.orders[this.customer.IDCliente] = this.rows;
        //Cambio local a bd
        // localStorage.setItem('orders', JSON.stringify(this.orders));
        this.process_order = 'disabled';
        this.delete_all_temporal();
        this.reset_totals();
      }
    });
  }

  reset_rows_pedido_procesado() {
    this.rows = [];
    OrdersService.set_rows(this.rows);
    this.orders[this.customer.IDCliente] = this.rows;
    //Cambio local a bd
    // localStorage.setItem('orders', JSON.stringify(this.orders));
    this.process_order = 'disabled';
    this.delete_all_temporal();
    this.reset_totals();
  }

  reset_totals() {
    this.customer['suma'] = 0;
    this.customer['suma_pp'] = 0;
    this.customer['subtotal'] = 0;
    this.customer['subtotal_pp'] = 0;
    this.customer['total_iva'] = 0;
    this.customer['total_iva_pp'] = 0;
    this.customer['total'] = 0;
    this.customer['total_pp'] = 0;
    this.isMinimunSale = false;
    this.totalFaltantePrecioMin = this.precioMinimoXCliente;
    this.activeSecondTab = false;
    // this.get_total(this.rows);
  }

  // select_product(id: any){
  //   this.get_product(id)
  // }

  add_row(product: any, detail = false) {
    this.rows = OrdersService.get_rows();

    let existe = this.rows.find((item) => item.clave === product.clave);

    product['status_amount'] = 'success';
    product['status_refresh'] = 'primary';
    product['claveConversion'] = product.claveConversion;

    //if(product.existencia > 0) {
    if (!existe) {
      if (detail == true) {
        this.successAlert('', 'Producto añadido al pedido');
      }
      if (product.existencia < 1) {
        product['status_amount'] = 'danger';
      }

      this.rows.push(product);

      //
      //console.log("TEST Productoo--"+JSON.stringify(product))
      //let test_product = {"clave":"4KAR-001","claveConversion":"4KAR-001","ClaveProveedor":"4KAR-001","descripcion":"FORD POLEA UNIV.GM CHRYSLER 76MM(3)METAL","descripcion_2":"POLEA LOCA","descripcion_3":"FORD POLEA UNIV.GM CHRYSLER 76MM(3)METAL","armadora":"FORD","IDCategoriaProducto":"4KAR1","producto":"POLEA","familia":"MOTOR","unidad_medida_clave":"EA","multiplo_venta":"1.0","clasificacion":"A","marca":"4KAR","urlimg":"http://dapesa-sirvete.s3.amazonaws.com/4KAR/4KAR-001.jpg","precio":"100.000000","en_favoritos":"0","imagen_marca":"https://dapesa-sirvete.s3.amazonaws.com/MARCAS/146_4KAR.png","imagen_armadora":"https://dapesa-sirvete.s3.amazonaws.com/ARMADORAS/3_FORD.png","existencia":"199511.00","precio_desc":"-","comision":"0","desc1":"0","desc3":"0","iva":16,"iva_desc":"0","valor_iva":"16","Mostrar":"2","Marca2":"4KAR","UnidadMedida":"UN","CategoriaProducto":"POLEAS","oculdesc":"5.000000","measurement_units":[],"request_amount":1,"PP":10,"idMarca":"4KAR","idMaterial":"4KAR-001","mostrar":"2","idPosicion":10,"precioLista":"100.000000","descuentoComercial":5,"total_pzas":1,"cantidadPiezas":"1","standard_price":"5.00","precioEstandar":"5.00","standard_discount":"2.0","descuentoEstandar":10,"standard_disc_porc":0.1,"comercial_discount":2,"comer_disc_porc":0.05,"price_disc_standar":5,"price_disc_comer":4.75,"price_disc_comer_pp":4.275,"total_row":4.75,"iva_conv":0.16,"status_amount":"success","status_refresh":"primary"}
      //this.rows.push(test_product)
      //

      OrdersService.set_rows(this.rows);
      this.orders[this.customer.IDCliente] = this.rows;

      if (!this.sort_order.includes(this.customer.IDCliente)) {
        this.sort_order.push(this.customer.IDCliente);
        this.names_sort_order.push(this.customer.Nombre);
        // console.log("Cliente---"+JSON.stringify(this.names_sort_order))
      }

      this.get_total(this.rows);
      let p = this.rows.find((item) => item.clave === product.clave);
      this.update_temporal(p, 'insert_art');
      // let num = Object.keys(this.orders).length
      let num = this.orders.length;
      let pos = this.sort_order[0];

      if (num > 3) {
        delete this.orders[pos];
      }

      if (this.sort_order.length > 3) {
        this.sort_order.splice(0, 1);
        this.names_sort_order.splice(0, 1);
      }

      //Cambio local a bd
      // localStorage.setItem('sort_order', JSON.stringify(this.sort_order));
      // localStorage.setItem('names_sort_order', JSON.stringify(this.names_sort_order));
      // localStorage.setItem('orders', JSON.stringify(this.orders));
      $('.second_filter').val('');
    } else {
      Swal.fire({
        title: 'Producto ya fue agregado',
        text: 'Verifique la cantidad solicitada',
        icon: 'info',
        timer: 2000,
      });
      // this.warningAlert("Validación", "El producto ya fue agregado, verifique la cantidad solicitada")
    }
    // else {
    //   alert("El producto no tiene existencias");
    // }

    this.clean_searcher();
    this.get_disabled(this.rows);
  }

  delete_row(index: any, clave: any) {
    Swal.fire({
      title: 'Confirmación',
      text: '¿Está seguro que desea eliminar?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.rows.splice(index, 1);
        OrdersService.set_rows(this.rows);
        this.get_total(this.rows);

        // delete this.orders[this.customer.IDCliente][clave]
        //Cambio local a bd
        // localStorage.setItem('orders', JSON.stringify(this.orders));
        this.delete_temporal(clave);
        this.get_disabled(this.rows);
      }
    });
  }

  show_detail(index: any) {
    //this.product = ProductsService.get_product();
    this.product = this.rows[index];
    let pos = (index + 1) * 10;
    // console.log(this.product)
    var body = {
      IdUni: `${this.product['unidad_medida_clave']}`,
      idCliente: this.customer.IDCliente,
      idMoneda: this.customer.IDMoneda,
      idOrganizacionVentas: this.customer.IDOrganizacionVentas,
      idCanalDistribucion: this.customer.IDCanalDistribucion,
      idArea: this.customer.IDSede,
      idMarca: this.product.marca,
      idMaterial: this.product.clave,
      cantidadPiezas: `${this.product.total_pzas}`,
      mostrar: '2',
      idJerarquiaCliente: this.customer.IDJerarquiaCliente,
      idPosicion: `${pos}`,
    };

    this.productsService.images(this.product).subscribe((data) => {
      this.product['images'] = data['data'];
    });

    this.update_price(body, false, index);

    this.productsService.equivalences(body).subscribe((data) => {
      this.product['equivalences'] = data['data'];
      //console.log("equivalences---"+JSON.stringify(this.product["equivalences"]))
    });

    //this.productsService.measurement_units(body).subscribe(data => {
    //this.product["measurement_units"] = data["data"];
    //console.log("measurement_units---"+JSON.stringify(this.product["measurement_units"]))
    //});

    const dialogRef = this.pop_detail.open(ProductDetailComponent, {
      data: this.product,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  show_detail2(clave: any) {
    let body = {
      idCliente: this.customer.IDCliente,
      idMaterial: clave,
      idMoneda: this.customer.IDMoneda,
      idOrganizacionVentas: this.customer.IDOrganizacionVentas,
      idArea: this.customer.IDSede,
      idJerarquiaCliente: this.customer.IDJerarquiaCliente,
      idCanalDistribucion: this.detail_user.IDCanalDistribucion,
    };

    this.productsService.get(body).subscribe((data) => {
      let product = data['data']['articulo'][0];

      // Descomentar promocionales
      this.promotions.forEach((product_promotion: any) => {
        if (product.clave == product_promotion.clave) {
          product['promocion'] = product_promotion.promocion;
          product['banner'] = product_promotion['banner'];
        }
      });

      const dialogRef = this.pop_detail.open(ProductDetailComponent, {
        data: product,
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
    });
  }

  update_temporal(product: any, action: any) {
    if (product) {
      let body = {
        idUsuario: this.detail_user.id_usuario,
        idCliente: this.customer.IDCliente,
        idMaterial: product.clave,
        cantidad: product.request_amount,
        cantidadConversion: product.total_pzas,
        precioLista: product.precio,
        precioEstandar: product.standard_price,
        precioFinal: product.price_disc_comer,
        importe: product.total_row,
        iva: product.iva,
        unidadMedida: product.unidad_medida_clave,
        descuentoOculto: product.oculdesc,
        descuentoEstandar: product.standard_discount,
        existenciaActual: product.existencia,
      };

      if (action == 'insert_art') {
        this.ordersService.insert_art(body).subscribe(
          (data) => {
            this.last_product = data;
          },
          (error) => {
            if (error.status === 409) {
              console.log('Ya existe producto');
            } else {
              console.log('Error' + JSON.stringify(error));
            }
          }
        );
      }

      if (action == 'update_art') {
        this.ordersService.update_art(body).subscribe(
          (data) => {
            this.last_product = data;
          },
          (error) => {
            this.errorAlert(
              'Error',
              'Ha ocurrido un error ' + JSON.stringify(error)
            );
          }
        );
      }
    }
  }

  update_temporal_bloque(products: any, action: any) {
    let body = {};
    let detalles = [];

    if (products) {
      for (let i = 0; i < products.length; i++) {
        let prod = {
          idUsuario: Number(this.detail_user.id_usuario),
          idCliente: this.customer.IDCliente,
          idMaterial: products[i].clave,
          //"idMoneda": this.customer.IDMoneda,
          cantidad: products[i].request_amount,
          cantidadConversion: products[i].total_pzas,
          precioLista: products[i].precio,
          precioEstandar: products[i].standard_price,
          precioFinal: products[i].price_disc_comer,
          importe: products[i].total_row,
          iva: products[i].iva,
          unidadMedida: products[i].unidad_medida_clave,
          descuentoOculto: products[i].oculdesc,
          descuentoEstandar: products[i].standard_discount,
          existenciaActual: products[i].existencia,
        };
        detalles.push(prod);
      }
      body['detalles'] = detalles;

      console.log(body);

      if (action == 'insert_art') {
        this.ordersService.insert_art_bloque(body).subscribe(
          (data) => {
            this.last_product = data;
            // console.log(data)
          },
          (error) => {
            if (error.status === 409) {
              console.log('Ya existe producto');
            } else {
              console.log('Error' + JSON.stringify(error));
            }
          }
        );
      }

      if (action == 'update_art') {
        this.ordersService.update_art_bloque(body).subscribe(
          (data) => {
            this.last_product = data;
          },
          (error) => {
            this.errorAlert(
              'Error',
              'Ha ocurrido un error ' + JSON.stringify(error)
            );
          }
        );
      }
    }
  }

  get_temporal() {
    console.log('get_temporal');

    this.ordersService
      .get_temporal(this.detail_user.id_usuario, this.customer.IDCliente)
      .subscribe(
        (data) => {
          console.log(data);
          this.temp_rows = data['data'];
          if (this.temp_rows.length > 0) {
            this.merge_temporal(this.temp_rows);
            // this.merge_temporal_version2(this.temp_rows);
          } else {
            const valor: any = {
              index: 0,
            };
            valor.index = 0;
            //     clearInterval(this.countdownInterval);
            //     console.log('el tiempo acabo')
            (<HTMLElement>(
              document.querySelectorAll('.mat-tab-label')[0]
            )).click();
            this.TabChange(valor);
            this.reset_rows_pedido_procesado();
          }

          // this.merge_temporal(this.temp_rows);
        },
        (error) => {
          this.errorAlert(
            'Error',
            'Ha ocurrido un error ' + JSON.stringify(error)
          );
        }
      );
  }

  delete_temporal(product_clave: any) {
    let body = {
      idUsuario: this.detail_user.id_usuario,
      idCliente: this.customer.IDCliente,
      idMaterial: product_clave,
    };

    this.ordersService.delete_art(body).subscribe(
      (data) => {
        this.last_product = data;
      },
      (error) => {
        this.errorAlert(
          'Error',
          'Ha ocurrido un error ' + JSON.stringify(error)
        );
      }
    );
  }

  delete_all_temporal() {
    Swal.fire({
      title: 'Eliminando pedido',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let body = {
      idUsuario: this.detail_user.id_usuario,
      idCliente: this.customer.IDCliente,
    };

    this.ordersService.delete_all_art(body).subscribe(
      (data) => {
        // console.log(data)
        Swal.close();
      },
      (error) => {
        this.errorAlert(
          'Error',
          'Ha ocurrido un error ' + JSON.stringify(error)
        );
      }
    );
  }

  delete_temporal_bloque(products_clave: any) {
    if (products_clave.length > 0) {
      console.log('delete temporal bloque');
      let body = {
        idUsuario: this.detail_user.id_usuario,
        idCliente: this.customer.IDCliente,
        idMaterial: products_clave.toString(),
      };

      this.ordersService.delete_art_bloque(body).subscribe(
        (data) => {
          this.last_product = data;
        },
        (error) => {
          this.errorAlert(
            'Error',
            'Ha ocurrido un error ' + JSON.stringify(error)
          );
        }
      );
    }
  }

  merge_temporal_version2(order_temp: any) {
    let productsToInsert = [];
    let claves = [];
    let produtsToUpdate = [];

    for (let i = 0; i < order_temp.length; i++) {
      let in_local = this.rows.find(
        (item) => item.clave === order_temp[i].IDMaterial
      );

      if (!in_local) {
        productsToInsert.push(order_temp[i]);

        //Insertamos lo de order_temp a this.rows
        //Get product
        //Meterlo a this.rows
      } else {
      }
      //Buscamos en la view si existen todos los productos
    }
    for (let i = 0; i < this.rows.length; i++) {
      let in_temp = order_temp.find(
        (item: { IDMaterial: any }) => item.IDMaterial === this.rows[i].clave
      );

      if (!in_temp) {
        this.rows.splice(i, 1);
        i--;
      } else {
        this.rows[i].request_amount = parseFloat(in_temp.Cantidad);

        this.rows[i].total_pzas = parseFloat(in_temp.Cantidad);
        this.rows[i].cant_conversion = parseFloat(in_temp.Cantidad_Conversion);
        this.rows[i].total_row = parseFloat(in_temp.Importe);
        produtsToUpdate.push(this.rows[i]);
      }
    }
    console.log('PRODCUTOS A INSERTAR EN VIEW');
    console.log(productsToInsert);
    if (productsToInsert.length > 0) {
      console.log('REDETEACTION');
      this.get_product_validate(productsToInsert);
    }
  }

  merge_temporal(order_temp: any) {
    let products = [];
    let claves = [];
    let produtsToUpdate = [];

    //Recorrer el carrito de bd para sincronizar con view
    for (let i = 0; i < order_temp.length; i++) {
      let in_local = this.rows.find(
        (item) => item.clave === order_temp[i].IDMaterial
      );

      if (!in_local) {
        claves.push(order_temp[i].IDMaterial);
      }
    }
    this.delete_temporal_bloque(claves);

    for (let i = 0; i < this.rows.length; i++) {
      let in_temp = order_temp.find(
        (item: { IDMaterial: any }) => item.IDMaterial === this.rows[i].clave
      );

      if (!in_temp) {
        products.push(this.rows[i]);
      } else {
        // this.rows[i].request_amount = parseFloat(in_temp.Cantidad);

        // this.rows[i].total_pzas = parseFloat(in_temp.Cantidad);
        // this.rows[i].cant_conversion = parseFloat(in_temp.Cantidad_Conversion);
        // this.rows[i].total_row = parseFloat(in_temp.Importe);
        produtsToUpdate.push(this.rows[i]);
      }
    }
    this.update_temporal_bloque(products, 'insert_art');

    this.update_temporal_bloque(produtsToUpdate, 'update_art');
  }

  // copy_rows_from_orders(copy_rows:any) {

  //   let porc_pp = parseFloat(this.customer.PP) / 100
  //   let num = Object.keys(this.orders).length
  //   let pos = this.sort_order[0];
  //   let cliente = this.names_sort_order[0];

  //   if(num >= 3) {
  //     Swal.fire({
  //       title: "Confirmación",
  //       text: `Tienes cotizaciones sin procesar. Puedes trabajar hasta 3 cotizaciones simultaneas. Si continuas, esta nueva cotización sustituirá a la más antigua: Cliente ${pos} ${cliente}`,
  //       icon: 'question',
  //       showCancelButton: true,
  //       confirmButtonText: 'Aceptar',
  //       cancelButtonText: 'Cancelar'
  //     }).then((result) => {
  //       if (result.value) {
  //         for (let i = 0; i < copy_rows.length; i++) {
  //           let product = copy_rows[i]
  //           product["copy"] = 1
  //           product["clave"] = copy_rows[i].IDMaterial
  //           product["idMaterial"] = copy_rows[i].IDMaterial
  //           product["status_refresh"] = "primary"
  //           product["familia"] = copy_rows[i].Terminologia
  //           product["multiploVenta"] = parseFloat(copy_rows[i].MultipoVenta)
  //           product["multiplo_venta"] = copy_rows[i].MultipoVenta
  //           product["descripcion"] = copy_rows[i].Descripcion
  //           product["CategoriaProducto"] = copy_rows[i].CategoriaProducto
  //           product["unidad_medida_clave"] = copy_rows[i].UnidadMedidaClave
  //           //product["unidad_medida_text"] = copy_rows[i].UnidadMedidad
  //           product["UnidadMedida"] = copy_rows[i].UnidadMedidad
  //           product["total_pzas"] = copy_rows[i].CantidadConversion
  //           product["standard_disc_porc"] = parseFloat(product.standard_discount) / 100
  //           product["cantidadPiezas"] = parseFloat(copy_rows[i].CantidadConversion).toFixed(0)
  //           product["request_amount"] = parseFloat(copy_rows[i].Cantidad).toFixed(0)
  //           product["Cantidad"] = parseFloat(copy_rows[i].Cantidad).toFixed(0)
  //           //product["cantidadConversion"] = 1//copy_rows[i].cantidadConversion
  //           product["precio"] = copy_rows[i].PrecioLista
  //           product["precioLista"] = parseFloat(copy_rows[i].PrecioLista).toFixed(6)
  //           product["standard_discount"] = copy_rows[i].DescuentoEstandar
  //           product["standard_disc_porc"] = parseFloat(product.standard_discount) / 100
  //           product["descuentoEstandar"] = parseFloat(copy_rows[i].DescuentoEstandar)
  //           product["standard_disc_porc"] = parseFloat(product.standard_discount) / 100
  //           product["price_disc_standar"] = copy_rows[i].PrecioEstandar
  //           product["oculdesc"] = copy_rows[i].DescuentoOculto
  //           product["precioEstandar"] = parseFloat(copy_rows[i].PrecioEstandar).toFixed(2)
  //           product["standard_price"] = copy_rows[i].PrecioEstandar
  //           product["comercial_discount"] = copy_rows[i].DescuentoOculto
  //           product["descuentoComercial"] = parseFloat(copy_rows[i].DescuentoOculto)
  //           product["comer_disc_porc"] = parseFloat(product.comercial_discount) / 100
  //           product["price_disc_comer"] = (copy_rows[i].TuPrecio)
  //           product["price_disc_comer_pp"] = (parseFloat(product.price_disc_comer) - parseFloat(product.price_disc_comer) * porc_pp);
  //           product["total_row"] = (copy_rows[i].TotalTuPrecio)
  //           product["idMarca"] = copy_rows[i].Marca2//IDMarca"QUIMICA TF"
  //           product["marca"] = copy_rows[i].Marca2
  //           product["existencia"] = copy_rows[i].Existencia
  //           product["valor_iva"] = parseFloat(copy_rows[i].IVA)
  //           product["iva"] = parseFloat(copy_rows[i].IVA)
  //           product["iva_conv"] = parseFloat(copy_rows[i].IVA) / 100
  //           product["idPosicion"] = copy_rows[i].Partida
  //           product["mostrar"] = "2"//Fijo

  //           this.get_inventory(product)

  //           this.rows.push(product)
  //         }

  //         OrdersService.set_rows(this.rows)
  //         this.orders[this.customer.IDCliente] = this.rows;

  //         if(!this.sort_order.includes(this.customer.IDCliente)){
  //           this.sort_order.push(this.customer.IDCliente)
  //           this.names_sort_order.push(this.customer.Nombre)
  //         }

  //         this.get_total(this.rows)

  //         if(num > 3) {
  //           delete this.orders[pos]
  //         }

  //         if (this.sort_order.length > 3) {
  //           this.sort_order.splice(0, 1 );
  //           this.names_sort_order.splice(0, 1);
  //         }

  //         //Update Local
  //         //Cambio local a bd
  //         // localStorage.setItem('sort_order', JSON.stringify(this.sort_order));
  //         // localStorage.setItem('names_sort_order', JSON.stringify(this.names_sort_order));
  //         // localStorage.setItem('orders', JSON.stringify(this.orders));
  //         this.CsvLoaded = 2

  //       }
  //     })
  //   }

  // }

  update_price_bloque(requests_materials: any) {
    // if(requests_materials.length){
    const materiales = requests_materials.requests_material;

    this.productsService
      .price_bloque(requests_materials)
      .subscribe((res): any => {
        res['data'].forEach((precio): any => {
          for (let i = 0; i <= materiales.length; i++) {
            if (materiales[i]) {
              if (materiales[i]['idMaterial'] == precio.IDMaterial) {
                materiales[i]['standard_price'] = precio.Precio;
                materiales[i]['precioEstandar'] = materiales[i].standard_price;
                materiales[i]['standard_discount'] = precio.Descuento;
                materiales[i]['descuentoEstandar'] = parseFloat(
                  precio.Descuento
                );
                materiales[i]['standard_disc_porc'] =
                  parseFloat(precio.Descuento) / 100;
                this.price_operations(false, -1, true, materiales[i]);
              }
            }
          }
        });
      });
    // }
  }

  update_price(body: any, add = false, index: any, detail = false) {
    Swal.fire({
      title: 'Actualizando Precio',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    if (this.product) {
      // console.log(this.searchInput?.nativeElement)
      this.productsService.price(body).subscribe((data) => {
        Swal.close();
        console.log('precio');
        console.log(data);

        console.log(this.product);
        this.searchInput?.nativeElement?.focus();
        this.product['standard_price'] = data['data'].Precio;
        this.product['precioEstandar'] = this.product.standard_price;
        this.product['standard_discount'] = data['data'].Descuento;
        this.product['descuentoEstandar'] = parseFloat(data['data'].Descuento);
        this.product['standard_disc_porc'] =
          parseFloat(data['data'].Descuento) / 100;

        this.price_operations(add, index, detail, this.product);
      });
    } else {
      this.productsService.price(body).subscribe((data) => {
        Swal.close();
        this.searchInput?.nativeElement?.focus();
        this.product_detail['standard_price'] = data['data'].Precio;
        this.product_detail['precioEstandar'] =
          this.product_detail.standard_price;
        this.product_detail['standard_discount'] = data['data'].Descuento;
        this.product_detail['descuentoEstandar'] = parseFloat(
          data['data'].Descuento
        );
        this.product_detail['standard_disc_porc'] =
          parseFloat(data['data'].Descuento) / 100;

        this.price_operations(add, index, detail, this.product_detail);
      });
    }
  }

  //false -1 true

  //this.price_operations(false, -1, true, materiales[i])
  price_operations(add: any, index: any, detail: any, product: any) {
    let porc_pp = parseFloat(this.customer.PP) / 100;

    product['comercial_discount'] = parseFloat(product.oculdesc);
    product['comer_disc_porc'] = parseFloat(product.oculdesc) / 100;
    product['price_disc_standar'] = product.standard_price; //(product.precio - ( product.precio * product.standard_disc_porc));
    product['price_disc_comer'] =
      product.price_disc_standar -
      product.price_disc_standar * product.comer_disc_porc;
    product['price_disc_comer_pp'] =
      parseFloat(product.price_disc_comer) -
      parseFloat(product.price_disc_comer) * porc_pp;
    if (product['existencia'] < parseFloat(product.total_pzas)) {
      product['total_row'] =
        parseFloat(product['price_disc_comer']) * product['existencia'];
    } else {
      product['total_row'] =
        parseFloat(product['price_disc_comer']) *
        parseFloat(product.total_pzas);
    }
    // product["total_row"] = (parseFloat(product.price_disc_comer) * parseFloat(product.total_pzas));
    product['iva_conv'] = parseFloat(product.valor_iva) / 100;

    Swal.close();

    // console.log(product);
    // console.log(add)
    if (add == true) {
      // let num = Object.keys(this.orders).length
      let num = this.orders.length;
      let pos = this.sort_order[0];
      let cliente = this.names_sort_order[0];

      this.add_row(product, detail);
      // if(num == 4) {
      //   Swal.fire({
      //     title: "Confirmación",
      //     text: `Tienes cotizaciones sin procesar. Puedes trabajar hasta 3 cotizaciones simultaneas. Si continuas, esta nueva cotización sustituirá a la más antigua: Cliente ${pos} ${cliente}`,
      //     icon: 'question',
      //     showCancelButton: true,
      //     confirmButtonText: 'Aceptar',
      //     cancelButtonText: 'Cancelar'
      //   }).then((result) => {
      //     if (result.value) {
      //       this.add_row(product, detail)
      //     }
      //   })
      // }
      // else {
      //   this.add_row(product, detail)
      // }
    }

    //update_info local
    this.rows = OrdersService.get_rows();
    if (this.rows.length > 0 && index >= 0) {
      this.rows[index].price_disc_standar = product['price_disc_standar'];
      this.rows[index].price_disc_comer = product['price_disc_comer'];
      this.rows[index].price_disc_comer_pp = product['price_disc_comer_pp'];
      this.rows[index].total_row = product['total_row'];
      this.rows[index].request_amount = product['request_amount'];
      this.rows[index].status_amount = product['status_amount'];
      this.rows[index].total_pzas = product['total_pzas'];
      OrdersService.set_rows(this.rows);
      this.orders[this.customer.IDCliente] = this.rows;

      //Cambio local a bd
      // localStorage.setItem('orders', JSON.stringify(this.orders));
    }
    // console.log(product)

    // $(`#product_clave_${product.claveConversion} .standard_price `).html(`${product.standard_price}`);
    // $(`#product_clave_${product.claveConversion} .total_row`).html(`${product.total_row}`);
    // $(`#product_clave_${product.claveConversion} .total_pzas`).html(`${parseFloat(product.total_pzas)}`);

    this.get_total(this.rows);

    if (add == false && detail == false) {
      if (this.rows[index].csv) {
        this.update_temporal_bloque(this.rows, 'update_art');
      } else {
        this.update_temporal(this.rows[index], 'update_art');
      }
    }

    this.disabled = '';
  }

  get_total(rows: any) {
    let porc_pp = parseFloat(this.customer.PP) / 100;

    this.customer['envio_pp'] =
      parseFloat(this.customer.CostoEnvio) -
      parseFloat(this.customer.CostoEnvio) * porc_pp;

    if (rows.length > 0) {
      let total = rows.reduce(
        (a: any, c: any) => a + parseFloat(c['total_row']),
        0
      );
      let iva = rows.reduce(
        (a: any, c: any) => a + parseFloat(c['iva_conv']),
        0
      );

      this.customer['suma'] = parseFloat(total);
      this.customer['suma_pp'] = total - total * porc_pp;
      this.customer['porc_seguro'] =
        parseFloat(this.customer.PorcentajeSeguro) / 100;
      this.customer['seguro'] = total * this.customer.porc_seguro;
      this.customer['seguro_pp'] =
        this.customer.suma_pp * this.customer.porc_seguro;
      this.customer['subtotal'] =
        total + parseFloat(this.customer.CostoEnvio) + this.customer.seguro;
      this.customer['subtotal_pp'] =
        parseFloat(this.customer.suma_pp) +
        parseFloat(this.customer.envio_pp) +
        parseFloat(this.customer.seguro_pp);
      this.customer['prom_iva'] = parseFloat(iva) / rows.length;
      this.customer['total_iva'] =
        parseFloat(this.customer.subtotal) * this.customer.prom_iva;
      this.customer['total_iva_pp'] =
        parseFloat(this.customer.subtotal_pp) * this.customer.prom_iva;
      this.customer['total'] =
        parseFloat(this.customer.subtotal) +
        parseFloat(this.customer.total_iva);
      this.customer['total_pp'] =
        parseFloat(this.customer.subtotal_pp) +
        parseFloat(this.customer.total_iva_pp);

      this.precioTotalPP =
        parseFloat(this.customer.subtotal_pp) +
        parseFloat(this.customer.total_iva_pp);

      if (this.mensajeErrorSeleccionTransportista) {
        Swal.fire('Seleccione Transportista para Calcular importe Minimo');
      } else {
        this.totalFaltantePrecioMin =
          this.precioMinimoXCliente - this.precioTotalPP;
        if (this.totalFaltantePrecioMin < 0) {
          this.totalFaltantePrecioMin = 0;
        }
        // this.totalFaltantePrecioMin = this.totalFaltantePrecioMin < 0 ? this.totalFaltantePrecioMin : 0;
        if (this.totalFaltantePrecioMin === 0) {
          this.isMinimunSale = true;
          this.activeSecondTab = true;
        } else {
          this.isMinimunSale = false;
          this.activeSearchAdv = false;
          this.totalFaltantePrecioMin =
            this.precioMinimoXCliente - this.precioTotalPP;
        }
      }
    } else {
      if (this.mensajeErrorSeleccionTransportista) {
        Swal.fire('Seleccione Transportista para Calcular importe Minimo');
      } else {
        this.totalFaltantePrecioMin =
          this.precioMinimoXCliente - this.precioTotalPP;
        if (this.totalFaltantePrecioMin < 0) {
          this.totalFaltantePrecioMin = 0;
        }
        // this.totalFaltantePrecioMin = this.totalFaltantePrecioMin < 0 ? this.totalFaltantePrecioMin : 0;
        if (this.totalFaltantePrecioMin === 0) {
          this.isMinimunSale = true;
          this.activeSecondTab = true;
        } else {
          this.isMinimunSale = false;
          this.activeSearchAdv = false;
          this.totalFaltantePrecioMin =
            this.precioMinimoXCliente - this.precioTotalPP;
        }
      }
      this.reset_totals();
    }
  }

  change_um(event: any, i: any) {
    let um = this.rows[i].measurement_units.find(
      (mea_unit: { IDUnidadMedida: any }) => mea_unit.IDUnidadMedida === event
    );
    let product = this.um_convertion(this.rows[i], um.IDUnidadMedida);

    this.rows[i] = product;
    this.price_operations(false, i, false, this.rows[i]);

    OrdersService.set_rows(this.rows);
    this.orders[this.customer.IDCliente] = this.rows;
    //Cambio local a bd
    // localStorage.setItem('orders', JSON.stringify(this.orders));
  }

  um_convertion(product: any, unidad_medida_clave: any) {
    // console.log(product)
    //CantidadConversion
    product['cant_conversion'] = product.measurement_units.find(
      (mea_unit: { IDUnidadMedida: any }) =>
        mea_unit.IDUnidadMedida === unidad_medida_clave
    );

    if (product['cant_conversion']) {
      product['total_pzas'] =
        Number(product['request_amount']) *
        Number(product['cant_conversion'].CantidadConversion);
    } else {
      product['total_pzas'] = Number(product['request_amount']);
    }

    ProductsService.set_product(product);
    return product;
  }

  get_inventory(product: any) {
    // console.log(product["clave"] + ' : ' +product["status_amount"])
    if (
      Number(product.total_pzas) > Number(product.existencia) &&
      Number(product.existencia) != 0
    ) {
      // this.warningAlert(
      //   "Atención",
      //   "No hay existencias suficientes, la cantidad solicitada se añadirá al pedido   Disponibles:" + product.existencia
      // )
      product['status_amount'] = 'warning';
      //$(`#product_clave_${product.clave} .amount input`).val(`${product.existencia}`);
    }
    if (Number(product.total_pzas) <= Number(product.existencia)) {
      product['status_amount'] = 'success';
    }
    if (Number(product.existencia) === 0) {
      product['status_amount'] = 'danger';
    }

    // console.log(product["clave"] +  ' : ' +product["status_amount"])
  }

  check_local(customer_id: any) {
    Swal.fire({
      title: 'Cargando Productos',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    this.isCustLoaded = 2;
    this.CsvLoaded = 2;

    //Obteniendo carrito de compra desde Base de datos
    // this.productsService.get_articulos_cart(request).subscribe(data=> {
    //   console.log(data)
    // })
    this.ordersService
      .get_temporal(this.detail_user.id_usuario, customer_id)
      .subscribe(
        (data) => {
          procesoLlenarOrden(data['data']);
        },
        (error) => {
          this.errorAlert(
            'Error',
            'Ha ocurrido un error ' + JSON.stringify(error)
          );
          Swal.close();
        }
      );

    // get_temporal() {
    //   this.ordersService.get_temporal(this.detail_user.id_usuario, this.customer.IDCliente).subscribe(
    //     data => {
    //       console.log(data)
    //       this.temp_rows = data["data"];
    //       this.merge_temporal(this.temp_rows)
    //     },
    //     error => {
    //       this.errorAlert("Error", "Ha ocurrido un error " + JSON.stringify(error))

    //     }
    //   );
    // }
    const procesoLlenarOrden = async (orders: any) => {
      const materialsToCart = [];
      this.product_detail = null;
      const articulosCart = [];

      const claves = orders.map((ord) => ord.IDMaterial);

      if (claves.length) {
        const params: any = {
          idCliente: this.customer.IDCliente,
          idMaterial: claves.toString(),
          idMoneda: this.customer.IDMoneda,
          idOrganizacionVentas: this.customer.IDOrganizacionVentas,
          idArea: this.customer.IDSede,
          idJerarquiaCliente: this.customer.IDJerarquiaCliente,
          idCanalDistribucion: this.customer.IDCanalDistribucion,
          //"idPosicion": `${pos}`,
        };

        this.productsService.get_all_detail(params).subscribe((res) => {
          const articulos = res.data.articulos;
          const unidades = res.data.unidades;

          const requestsToPrice = [];

          const pMaterialesArray = [];
          articulos.forEach((art: any, index: any) => {
            const newRequest: any = {};

            if (unidades?.[art.clave]?.[0].length) {
              // const unidades_format = unidades[art.clave][0]
              const array_unidades = [];

              unidades[art.clave].forEach((art) => {
                const measurement_units_format = {
                  IDUnidadMedida: art[0],
                  UnidadMedida: art[1],
                  Cantidad: art[2],
                  CantidadConversion: art[3],
                };
                array_unidades.push(measurement_units_format);
              });

              art['measurement_units'] = array_unidades;
            } else {
              art['measurement_units'] = [];
            }

            pMaterialesArray.push({
              Posicion: index,
              IDMaterial: art.clave,
              IDCategoria: art.IDCategoriaProducto,
              Marca: art.marca,
            });

            // detalleArt["measurement_units"]  falta tema con unidades
            const productoEncontradoLocal = orders.find(
              (item) => item.IDMaterial === art.clave
            );
            art['iva'] = parseFloat(art.valor_iva);
            if (!productoEncontradoLocal) {
              art['request_amount'] = Number(art.multiplo_venta);
            } else {
              art['request_amount'] = parseInt(
                productoEncontradoLocal['Cantidad']
              ).toFixed();
            }
            // art["request_amount"] = parseInt(productoEncontradoLocal["Cantidad"]).toFixed();
            art['multiploVenta'] = Number(art.multiplo_venta);
            art['PP'] = this.customer.PP;
            art['idMarca'] = art.marca;
            art['idMaterial'] = art.clave;
            art['mostrar'] = '2';
            art['precioLista'] = art.precio;
            art['descuentoComercial'] = parseFloat(art.oculdesc);

            newRequest['cantidadPiezas'] = `${art['total_pzas']}`;
            art['cantidadPiezas'] = `${art['total_pzas']}`;

            art = this.um_convertion(art, art.unidad_medida_clave);

            newRequest['cantidadPiezas'] = `${art['total_pzas']}`;
            art['cantidadPiezas'] = `${art['total_pzas']}`;
            // console.log(art)
            let pos = index;
            // request_material["idPosicion"] = `${pos}`;
            newRequest['idPosicion'] = `${pos}`;
            newRequest['idMaterial'] = `${art['clave']}`;

            newRequest['idMarca'] = art.marca;
            newRequest['mostrar'] = '2';
            newRequest['IdUni'] = `${art['unidad_medida_clave']}`;

            const existe = articulosCart.some(
              (item) => item.clave === art.clave
            );
            if (!existe) {
              articulosCart.push(art);

              requestsToPrice.push(newRequest);
            } else {
              console.log('-');
            }

            // this.update_price(request_material, false, -1, true)

            this.get_inventory(art);

            // materialsToCart.push(art);
            // art = this.um_convertion(art, art.unidad_medida_clave)
          });

          // Descomentar promocionales
          const params_to_promocionales = {
            pMateriales: pMaterialesArray,
            pIDOrganizacionVentas: this.customer.IDOrganizacionVentas,
            pIDCanalDistribucion: this.customer.IDCanalDistribucion,
            pIDArea: this.customer.IDSede,
          };
          this.productsService
            .get_promocionales(params_to_promocionales)
            .subscribe((res) => {
              articulos.forEach((product: any) => {
                res.body.forEach((promo: any) => {
                  if (product.clave === promo.IdMaterial) {
                    product['promocion'] = true;
                    product['banner'] = promo.Banner;
                  }
                });
              });
              // this.promotionsLocal = this.products.articulos;
            });
          const request_price = {
            requests_material: requestsToPrice,
            params: params,
          };
          // console.log(request_price)

          this.productsService
            .price_bloque(request_price)
            .subscribe((res): any => {
              Swal.close();

              if (res['data'].length) {
                res['data'].forEach((precio: any) => {
                  for (let i = 0; i <= articulosCart.length; i++) {
                    if (articulosCart[i]) {
                      if (
                        articulosCart[i]['idMaterial'] == precio['IDMaterial']
                      ) {
                        articulosCart[i]['standard_price'] = precio['Precio'];
                        articulosCart[i]['precioEstandar'] =
                          articulosCart[i]['standard_price'];
                        articulosCart[i]['standard_discount'] =
                          precio['Descuento'];
                        articulosCart[i]['descuentoEstandar'] = parseFloat(
                          precio['Descuento']
                        );
                        articulosCart[i]['standard_disc_porc'] =
                          parseFloat(precio['Descuento']) / 100;
                        this.price_operations(
                          false,
                          -1,
                          true,
                          articulosCart[i]
                        );

                        materialsToCart.push(articulosCart[i]);
                      }
                    }
                  }
                });
              } else {
                const precio = res['data'];

                for (let i = 0; i <= articulosCart.length; i++) {
                  if (articulosCart[i]) {
                    if (
                      articulosCart[i]['idMaterial'] == precio['IDMaterial']
                    ) {
                      articulosCart[i]['standard_price'] = precio['Precio'];
                      articulosCart[i]['precioEstandar'] =
                        articulosCart[i]['standard_price'];
                      articulosCart[i]['standard_discount'] =
                        precio['Descuento'];
                      articulosCart[i]['descuentoEstandar'] = parseFloat(
                        precio['Descuento']
                      );
                      articulosCart[i]['standard_disc_porc'] =
                        parseFloat(precio['Descuento']) / 100;

                      this.price_operations(false, -1, true, articulosCart[i]);

                      materialsToCart.push(articulosCart[i]);
                    }
                  }
                }
              }

              this.rows = materialsToCart;
              OrdersService.set_rows(this.rows);

              this.get_total(this.rows);
            });
        });
      } else {
        Swal.close();
      }

      // for (let i = 0; i < orders.length; i++) {
      //   var request_material: any = {
      //     "idCliente": this.customer.IDCliente,
      //     "idMaterial": orders[i].IDMaterial,
      //     "idMoneda": this.customer.IDMoneda,
      //     "idOrganizacionVentas": this.customer.IDOrganizacionVentas,
      //     "idArea": this.customer.IDSede,
      //     "idJerarquiaCliente": this.customer.IDJerarquiaCliente,
      //     "idCanalDistribucion": this.customer.IDCanalDistribucion,
      //     //"idPosicion":"10"
      //   };

      //   const res = await this.productsService.get(request_material).toPromise();

      //   this.product_detail = res.data['articulo'][0]; //Se toma el primero porque ese trae los precios

      //   this.product_detail["measurement_units"] = res.data["unidades"];
      //   this.product_detail["iva"] = parseFloat(this.product_detail.valor_iva)

      //   let existe = orders.find(item => item.IDMaterial === this.product_detail.clave)

      //       if(!existe){
      //         this.product_detail["request_amount"] = Number(this.product_detail.multiplo_venta);
      //       }
      //       else {
      //         this.product_detail["request_amount"] = parseInt(existe["Cantidad"]).toFixed();
      //       }

      //   this.product_detail["multiploVenta"] = Number(this.product_detail.multiplo_venta);
      //   this.product_detail["PP"] = this.customer.PP
      //   this.product_detail["idMarca"] = this.product_detail.marca
      //   this.product_detail["idMaterial"] = this.product_detail.clave
      //   this.product_detail["mostrar"] = "2"
      //   this.product_detail["precioLista"] = this.product_detail.precio
      //   this.product_detail["descuentoComercial"] = parseFloat(this.product_detail.oculdesc)
      //   request_material["cantidadPiezas"] = `${this.product_detail["total_pzas"]}`;
      //   this.product_detail["cantidadPiezas"] = `${this.product_detail["total_pzas"]}`;
      //   this.product_detail = this.um_convertion(this.product_detail, this.product_detail.unidad_medida_clave)

      //   request_material["idMarca"] = this.product_detail.marca;
      //   request_material["cantidadPiezas"] = `${this.product_detail["total_pzas"]}`;
      //   this.product_detail["cantidadPiezas"] = `${this.product_detail["total_pzas"]}`;
      //   request_material["mostrar"] = "2";
      //   // console.log(this.this.product_detail)
      //   request_material["IdUni"] = `${this.product_detail["unidad_medida_clave"]}`
      //   let pos = (this.rows.length + 1 ) * 10

      //   request_material["idPosicion"] = `${pos}`;
      //   // this.update_price(request_material, true, -1, false)
      //   this.update_price(request_material, false, -1, true)
      //   this.get_inventory( this.product_detail)
      //   materialsToCart.push(this.product_detail);

      // }

      // this.orders = JSON.parse(localStorage.getItem("orders") || '{}');
      // console.log(this.orders[this.customer_id])
      // this.sort_order = JSON.parse(localStorage.getItem("sort_order") || '[]');
      // this.names_sort_order = JSON.parse(localStorage.getItem("names_sort_order") || '[]');

      // this.rows = materialsToCart;
      // OrdersService.set_rows(this.rows)
      // console.log(this.rows)
      // this.get_total(this.rows);

      //update request amount
      window.setTimeout(() => {
        for (let i = 0; i < this.orders.length; i++) {
          let amount_request = this.orders[i].request_amount;
          let clave = this.orders[i].claveConversion;

          $(`#product_clave_${clave} .amount input`).val(amount_request);
        }
      }, 1000);
      // if(this.copy_rows && this.copy_rows.length > 0) {
      //   // this.copy_rows_from_orders(this.copy_rows)
      // }
      // else {

      //   this.rows = materialsToCart;
      //   OrdersService.set_rows(this.rows)
      //   this.get_total(this.rows);

      //   Swal.close()
      //   //update request amount
      //     window.setTimeout(() => {
      //       for (let i = 0; i < this.orders.length; i++) {
      //         let amount_request = this.orders[i].request_amount;
      //         let clave = this.orders[i].claveConversion

      //         $(`#product_clave_${clave} .amount input`).val(amount_request)
      //       }
      //     }, 1000);

      // if(Object.keys(this.orders).includes(customer_id)){
      //   this.rows = this.orders[customer_id];
      //   OrdersService.set_rows(this.rows)
      //   this.get_total(this.rows);

      //   //update request amount
      //   window.setTimeout(() => {
      //     for (let i = 0; i < this.orders[this.customer.IDCliente].length; i++) {
      //       let amount_request = this.orders[this.customer.IDCliente][i].request_amount;
      //       let clave = this.orders[this.customer.IDCliente][i].claveConversion

      //       $(`#product_clave_${clave} .amount input`).val(amount_request)
      //     }
      //   }, 1000);
      // }
      // }
    };
  }

  update_address(selected: any) {
    this.address_entr_detail = [];
    this.address_entr_detail.push(
      this.customer_address.find(
        (item: { consecutivo: any }) => item.consecutivo === selected
      )
    );
  }

  update_transporte(selected: any) {
    this.transporte = [];

    this.productsService
      .getPrecioMinimo(this.idSedeImporteMinimo, selected)
      .subscribe(
        (data) => {
          const importeMinimoCustomer =
            data['data']['precio_minimo'][0]?.ImporteMinimo;
          if (importeMinimoCustomer) {
            this.precioMinimoXCliente = importeMinimoCustomer;
          } else {
            this.precioMinimoXCliente = 2000;
          }

          this.totalFaltantePrecioMin = importeMinimoCustomer;

          //  procedimientoSiguienteImporteMinimo();
          this.mensajeErrorSeleccionTransportista = false;

          //  this.rows = OrdersService.get_rows()
          this.get_total(this.rows);
        },
        (error) => {
          console.error('Error en la respuesta del servidor:', error); // Imprime el mensaje de error en la consola
          // ... manejo del error en tu aplicación ...
        }
      );
    this.transporte.push(
      this.all_transportistas.find(
        (item: { IDTransportista: any }) => item.IDTransportista === selected
      )
    );
  }

  save_extra_data() {
    this.num_order = $('#num_order').val();
    this.obs_order = $('#obs_order').val();
    if ($('#bill').is(':checked')) {
      this.bill = 'S';
    } else {
      this.bill = 'N';
    }
  }

  validate_rows(isExport?: boolean) {
    Swal.fire({
      title: 'Validando Productos',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    console.log('Validando Productos');
    let filteredArray = this.rows.filter((element) => element !== null);
    this.rows = filteredArray;
    if (this.rows.length > 0) {
      this.isVerified = 1;
      for (let i = 0; i < this.rows.length; i++) {
        let pos = (i + 1) * 10;
        this.rows[i]['idPosicion'] = `${pos}`;
        this.rows[i]['request_amount'] = parseFloat(
          this.rows[i].request_amount
        );
      }

      let params = {
        idUsuario: this.detail_user.id_usuario,
        idCliente: this.customer.IDCliente,
        idMoneda: this.customer.IDMoneda,
        idOrganizacionVentas: this.customer.IDOrganizacionVentas,
        idArea: this.customer.IDSede,
        idCanalDistribucion: this.detail_user.IDCanalDistribucion,
        idJerarquiaCliente: this.customer.IDJerarquiaCliente,
        articulos: this.rows,
      };

      this.ordersService.validate_temp(params).subscribe(
        (data) => {
          console.log(data);
          // this.CsvLoaded = 2;
          Swal.close();
          this.updated_rows = data['data'];
          // this.updated_rows.huboCambios  = 1;
          if (isExport === false || !isExport) {
            this.getPromocionalesGanados(this.rows, this.updated_rows);
          }

          this.refresh_rows(this.updated_rows);

          // Swal.close()

          this.isVerified = 2;
          this.CsvLoaded = 2;
        },
        (error) => {
          Swal.close();
          //alert("Ha ocurrido un error "+JSON.stringify(error));
          this.errorAlert('Error', 'Ha ocurrido un error ' + error);
          this.isVerified = 0;
          this.CsvLoaded = 0;
        }
      );
    } else {
      Swal.close();
      this.isVerified = 0;
      // this.CsvLoaded = 0;
    }
  }

  getPromocionalesGanados(rowsToPromotions: any, client_data: any) {
    Swal.fire({
      title: 'Cargando Promocionales Ganados',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const arrMaterial = rowsToPromotions.map((art: any, index: any) => {
      const cantidadSurtir =
        art.existencia < art.total_pzas ? art.existencia : art.total_pzas;
      return {
        Posicion: index + 1,
        IDMaterial: art.idMaterial,
        IDCategoria: art.IDCategoriaProducto,
        Marca: art.idMarca,
        CantidadSurtir: parseInt(cantidadSurtir),
        UM: 'EA',
        TuPrecio: art.price_disc_comer_pp,
      };
    });
    //  console.log(client_data)
    const request_prom_ganados = {
      pIDCliente: client_data.cliente.idCliente,
      pMateriales: arrMaterial,
      pIDMoneda: client_data.cliente.idMoneda,
      pIDOrganizacionVentas: client_data.cliente.idOrganizacionVentas,
      pIDArea: client_data.cliente.idArea,
      pIDJerarquiaCliente: client_data.cliente.idJerarquiaCliente,
      pIDCanalDistribucion: client_data.cliente.idCanalDistribucion,
    };

    this.productsService
      .get_promocionales_ganados(request_prom_ganados)
      .subscribe((res) => {
        const promo_products = res.body;

        const pMateriales = promo_products.map((prod: any) => {
          return {
            IdPromocional: prod.IdPromocional,
            IDMaterial: prod.IDMaterial,
            CantidadEntregar: prod.CantidadEntregar,
            Banner: prod.Banner,
          };
        });

        const request = {
          pIDCliente: client_data.cliente.idCliente,
          pMateriales: pMateriales,
          pIDMoneda: client_data.cliente.idMoneda,
          pIDOrganizacionVentas: client_data.cliente.idOrganizacionVentas,
          pIDArea: client_data.cliente.idArea,
          pIDJerarquiaCliente: client_data.cliente.idJerarquiaCliente,
          pIDCanalDistribucion: client_data.cliente.idCanalDistribucion,
        };

        this.productsService
          .get_promocionales_detalles(request)
          .subscribe(async (res) => {
            const productsNotPrice = [];
            //Le ponemos el id promocional a this.product

            for (let i = 0; i < res.length; i++) {
              this.product = {};
              this.product = res[i];
              pMateriales.find((prod: any) => {
                if (prod.IDMaterial === this.product.clave) {
                  this.product.id_promocional = prod.IdPromocional;
                  this.product.promocion_ganada = true;
                  this.product.banner = prod.Banner;
                }
              });
              // this.product.id_promocional = res[i].;
              // console.log(res[this.isCustLoaded])
              let body: any = {
                idCliente: this.customer.IDCliente,
                idMaterial: this.product.clave,
                idMoneda: this.customer.IDMoneda,
                idOrganizacionVentas: this.customer.IDOrganizacionVentas,
                idArea: this.customer.IDSede,
                idJerarquiaCliente: this.customer.IDJerarquiaCliente,
                idCanalDistribucion: this.customer.IDCanalDistribucion,
                idMarca: this.product.marca,
                mostrar: '2',
                IdUni: this.product.unidad_medida_clave,
                idPosicion: `${i + 1} `,
                cantidadPiezas: this.product.CantidadSolicitada,
                //"idPosicion":"10"
              };
              this.product.request_amount = this.product.CantidadSolicitada;
              this.product['total_pzas'] = Number(
                this.product['request_amount']
              );
              this.product['multiploVenta'] = Number(
                this.product.multiplo_venta
              );
              this.product['PP'] = this.customer.PP;
              this.product['idMarca'] = this.product.marca;
              this.product['idMaterial'] = this.product.clave;
              this.product['mostrar'] = '2';
              //this.product["idPosicion"] = 10;
              this.product['precioLista'] = this.product.precio;
              this.product['descuentoComercial'] = parseFloat(
                this.product.oculdesc
              );
              this.product['iva'] = parseFloat(this.product.valor_iva);
              // this.product = this.um_convertion(this.product, this.product.unidad_medida_clave)

              // const updatePriceObservable = this.update_price(body, true, i, false);

              const pricePromise = new Promise((resolve, reject) => {
                this.productsService.price(body).subscribe((data) => {
                  // ... Procesar la respuesta
                  resolve(data);
                });
              });

              const data = await pricePromise;
              if (typeof data['data'] === 'string') {
                // console.log(this.product)
                productsNotPrice.push(this.product.id_promocional);
              } else {
                this.product['standard_price'] = data['data'].Precio;
                this.product['precioEstandar'] = this.product.standard_price;
                this.product['standard_discount'] = data['data'].Descuento;
                this.product['descuentoEstandar'] = parseFloat(
                  data['data'].Descuento
                );
                this.product['standard_disc_porc'] =
                  parseFloat(data['data'].Descuento) / 100;

                // this.price_operations(add, index, detail, this.product)
                let porc_pp = parseFloat(this.customer.PP) / 100;

                this.product['comercial_discount'] = parseFloat(
                  this.product.oculdesc
                );
                this.product['comer_disc_porc'] =
                  parseFloat(this.product.oculdesc) / 100;
                this.product['price_disc_standar'] =
                  this.product.standard_price; //(this.product.precio - ( this.product.precio * this.product.standard_disc_porc));
                this.product['price_disc_comer'] =
                  this.product.price_disc_standar -
                  this.product.price_disc_standar *
                    this.product.comer_disc_porc;
                this.product['price_disc_comer_pp'] =
                  parseFloat(this.product.price_disc_comer) -
                  parseFloat(this.product.price_disc_comer) * porc_pp;
                if (
                  this.product['existencia'] <
                  parseFloat(this.product.total_pzas)
                ) {
                  this.product.request_amount = this.product['existencia'];
                  this.product['total_pzas'] = this.product['existencia'];
                  this.product['total_row'] =
                    parseFloat(this.product['price_disc_comer']) *
                    this.product['existencia'];
                } else {
                  this.product['total_row'] =
                    parseFloat(this.product['price_disc_comer']) *
                    parseFloat(this.product.total_pzas);
                }
                // this.product["total_row"] = (parseFloat(this.product.price_disc_comer) * parseFloat(this.product.total_pzas));
                this.product['iva_conv'] =
                  parseFloat(this.product.valor_iva) / 100;

                this.product['status_amount'] = 'success';
                this.product['status_refresh'] = 'primary';
                this.product['claveConversion'] = this.product.claveConversion;

                // this.update_price(body, true, i, false)

                // this.add_row(this.product)

                this.rows.push(this.product);
                // this.updated_rows.push(this.product)
              }

              productsNotPrice.map((row) => {
                this.rows = this.rows.filter(
                  (item) => item.id_promocional !== row
                );
              });
            }
            console.log(this.rows);
            this.get_total(this.rows);

            Swal.close();
            this.startTimer(1);
          });
      });
  }

  startTimer(minutes: number) {
    this.remainingTime = minutes * 60;
    this.interval = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime--;
      } else {
        this.stopTimer();

        Swal.fire({
          title: 'Tiempo de espera agotado',
          text: 'Por favor, vuelve a procesar el pedido',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Aceptar',
        });
        const valor: any = {
          index: 0,
        };
        valor.index = 0;
        //     clearInterval(this.countdownInterval);
        //     console.log('el tiempo acabo')
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).click();
        this.TabChange(valor);
      }
    }, 1000);
    // this.remainingMinutes = minutes;
    // this.remainingMinutes *= 60; // Convierte los minutos en segundos
    // this.countdownInterval = setInterval(() => {
    //   if (this.remainingMinutes > 0) {
    //     // console.log(this.remainingMinutes)
    //     this.remainingMinutes--;
    //   } else {
    //     const valor:any = {
    //       index: 0
    //     };
    //     valor.index = 0;
    //     clearInterval(this.countdownInterval);
    //     console.log('el tiempo acabo')
    //     this.TabChange(valor)
    //     // Aquí puedes ejecutar el evento cuando el temporizador llegue a 0
    //     // Por ejemplo, mostrar un mensaje o realizar alguna acción
    //   }
    // }, 1000);
  }
  stopTimer() {
    clearInterval(this.interval);
    this.setFlag(false);
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} min ${remainingSeconds} s`;
  }

  refresh_rows(updated_rows: any) {
    OrdersService.set_rows(this.rows);
    // this.price_operations(false, refresh_index, true, this.rows[refresh_index])
    // this.get_total(this.rows)
    //this.get_total(this.rows)
    if (updated_rows.huboCambios == 1) {
      for (let i = 0; i < updated_rows.articulos.length; i++) {
        let claves = this.rows.map((obj) => obj.clave);
        let refresh_index = claves.indexOf(
          updated_rows.articulos[i].idMaterial
        );

        if (updated_rows.articulos[i].actualizado == 1) {
          //console.log("Hubo cambios Before---" + JSON.stringify(this.rows[refresh_index]))
          // this.rows[refresh_index].status_refresh = "danger"
          // $(`#product_clave_${this.rows[refresh_index].claveConversion}`).removeClass("primary");
          // $(`#product_clave_${this.rows[refresh_index].claveConversion}`).addClass("danger");
          this.rows[refresh_index].descuentoComercial =
            updated_rows.articulos[i].descuentoComercial;
          this.rows[refresh_index].oculdesc =
            updated_rows.articulos[i].descuentoComercial;
          this.rows[refresh_index].descuentoEstandar =
            updated_rows.articulos[i].descuentoEstandar;
          this.rows[refresh_index].standard_discount =
            updated_rows.articulos[i].descuentoEstandar;
          this.rows[refresh_index].precioLista =
            updated_rows.articulos[i].precioLista;
          this.rows[refresh_index].precio =
            updated_rows.articulos[i].precioLista;
          this.rows[refresh_index].precioEstandar =
            updated_rows.articulos[i].precioEstandar;
          this.rows[refresh_index].standard_price =
            updated_rows.articulos[i].precioEstandar;
          this.rows[refresh_index].price_disc_standar =
            updated_rows.articulos[i].precioEstandar;
          this.rows[refresh_index].valor_iva = updated_rows.articulos[i].iva;

          // this.price_operations(false, refresh_index, false, this.rows[refresh_index])
          this.price_operations(
            false,
            refresh_index,
            true,
            this.rows[refresh_index]
          );
          this.get_total(this.rows);
          //console.log("After---"+JSON.stringify(this.rows[refresh_index].precioEstandar))
          //console.log("updated_rows---"+JSON.stringify(updated_rows.articulos[i].precioEstandar))
          //Update Local
          OrdersService.set_rows(this.rows);
          this.orders[this.customer.IDCliente] = this.rows;
          //Cambio local a bd
          // localStorage.setItem('orders', JSON.stringify(this.orders));
        } else {
          console.log('No cambios art---');
          this.update_all_dangers();
        }
      }
    } else {
      this.update_all_dangers();
    }
  }

  update_all_dangers() {
    this.rows = OrdersService.get_rows();
    let dangers = this.rows.filter((obj) => obj.status_refresh == 'danger');

    for (let i = 0; i < dangers.length; i++) {
      let claves = this.rows.map((obj) => obj.clave);
      let index = claves.indexOf(dangers[i].clave);
      //let index = this.rows.findIndex(((obj: { clave: any; }) => obj.clave == dangers[i].clave));
      this.rows[index].status_refresh = 'danger';
      $(`#product_clave_${dangers[i].claveConversion}`).removeClass('primary');
      $(`#product_clave_${dangers[i].claveConversion}`).addClass('danger');

      //Update Local
      OrdersService.set_rows(this.rows);
      this.orders[this.customer.IDCliente] = this.rows;

      //Cambio local a bd
      // localStorage.setItem('orders', JSON.stringify(this.orders));
    }
  }

  new_order() {
    if (this.NewOrderForm.invalid) {
      return;
    }
    Swal.fire({
      title: 'Validando Ventana',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const objectValidateExist = {
      id_tlmk: this.detail_user.id_usuario,
      id_cliente: this.customer_id,
      id_bandera: this.id_flag_validation,
    };
    this.customersService
      .getValidacionCliente(objectValidateExist)
      .subscribe((res) => {
        console.log(res.responseValidacion[0].ID_BANDERA);
        console.log(this.id_flag_validation);
        Swal.close();
        if (res.responseValidacion[0].ID_BANDERA === this.id_flag_validation) {
          this.setFlag(true);
          //Bandera Valida procesar pedido
          Swal.fire({
            title: 'Confirmación',
            text: `Se enviará a procesar un pedido para el cliente: ${this.customer.IDCliente} ${this.customer.Nombre} ¿Está seguro de continuar?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cancelar',
          }).then((result) => {
            if (result.value) {
              this.addPromosToBD(this.rows);
              let priority = this.customer.IDPrioridadEntrega;
              // let priority = $("#select2-priority").find("option:selected").val()
              let params = {
                idCliente: this.customer.IDCliente,
                idUsuario: this.detail_user.id_usuario,
                usuario: '',
                idOrganizacionVentas: this.customer.IDOrganizacionVentas,
                prontoPago: parseFloat(this.customer.PP),
                seguro: parseFloat(this.customer.PorcentajeSeguro),
                costoEnvio: parseFloat(this.customer.CostoEnvio),
                idTransportista: this.transporte[0].IDTransportista,
                UUIDDireccionEntrega: this.address_entr_detail[0].consecutivo,
                tuPrecioSuma: this.customer.suma_pp,
                tuPrecioSeguro: this.customer.seguro_pp,
                tuPrecioCostoEnvio: this.customer.envio_pp,
                tuPrecioSubTotal: this.customer.subtotal_pp,
                tuPrecioIva: this.customer.total_iva_pp,
                tuPrecioTotal: this.customer.total_pp,
                ordenDeCompra: this.num_order,
                observaciones: this.obs_order,
                idEmpleado: this.detail_user.IDEmpleado,
                idPrioridadEntrega: priority,
                idCanalDistribucion: this.detail_user.IDCanalDistribucion,
                aMostrador: this.bill,
              };

              this.ordersService.new(params).subscribe(
                (data) => {
                  this.order = data;
                  // console.log(data)

                  this.stopTimer();
                  this.successAlert(
                    '',
                    `Pedido enviado con éxito<br>Id Orden: ${this.order.data[0].IDPedido} Num Arts: ${this.order.data[0].RenglonesGrabados}`,
                    true
                  );
                  setTimeout(() => {
                    this.reset_rows_pedido_procesado();
                    this.router.navigate(['./customers']);
                    this.triggerReload();
                  }, 5000);
                },
                (error) => {
                  this.errorAlert('Error', 'Ha ocurrido un error ' + error);
                }
              );
            }
          });
        } else {
          Swal.fire({
            title: '',
            confirmButtonText: 'OK',
          });
          Swal.fire({
            title: 'Ventanas duplicadas',
            text: 'No puede proceder el pedido en esta ventana',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Aceptar',
          });
          const valor: any = {
            index: 0,
          };
          valor.index = 0;
          //     clearInterval(this.countdownInterval);
          //     console.log('el tiempo acabo')
          (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).click();
          this.TabChange(valor);
        }
      });
  }

  addPromosToBD(products: any) {
    //Filtrear promos
    //Loader Swal
    Swal.fire({
      title: 'Guardando promocionales',
      text: 'Por favor, espera...',
      allowOutsideClick: false,
      showCancelButton: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    const promos = products.filter(
      (product: any) => product.marca === 'MERCADOTECNIA'
    );
    // console.log(promos)
    Swal.close();
    promos.map((promo: any) => {
      this.update_temporal(promo, 'insert_art');
    });
  }

  moveToSelectedTab(tabName: string) {
    for (
      let i = 0;
      i < document.querySelectorAll('.mat-tab-label-content').length;
      i++
    ) {
      if (
        (<HTMLElement>document.querySelectorAll('.mat-tab-label-content')[i])
          .innerText == tabName
      ) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[i]).click();
        this.finalWindow = true;
      }
    }

    if (tabName == 'Resumen de pedido del cliente') {
      Swal.fire({
        title: 'Validando Ventana',
        text: 'Por favor, espera...',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const objectValidateExist = {
        id_tlmk: this.detail_user.id_usuario,
        id_cliente: this.customer_id,
        id_bandera: this.id_flag_validation,
      };
      this.customersService
        .getValidacionCliente(objectValidateExist)
        .subscribe((res) => {
          console.log(res);
          Swal.close();
          console.log(this.id_flag_validation);
          if (
            res.responseValidacion[0].ID_BANDERA === this.id_flag_validation
          ) {
            this.get_temporal();

            setTimeout(() => {
              this.validate_rows();
              this.get_disabled(this.rows);
            }, 1000);
          } else {
            Swal.fire({
              title: '',
              confirmButtonText: 'OK',
            });
            Swal.fire({
              title: 'Ventanas duplicadas',
              text: 'No puede proceder al paso 2 en esta ventana',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Aceptar',
            });
            const valor: any = {
              index: 0,
            };
            valor.index = 0;
            //     clearInterval(this.countdownInterval);
            //     console.log('el tiempo acabo')
            (<HTMLElement>(
              document.querySelectorAll('.mat-tab-label')[0]
            )).click();
            this.TabChange(valor);
          }
        });
    } else {
      // this.setFlag(false);
    }
  }

  TabChange($event: any) {
    if ($event.index == 0) {
      this.stopTimer();
      this.update_all_dangers();
      this.finalWindow = false;
      // console.log("Tab change one")
      this.deletePromotionsToRow();
    }

    if ($event.index == 1 && this.finalWindow == false) {
      // this.get_temporal();
      // this.validate_rows();

      Swal.fire({
        title: 'Validando Ventana',
        text: 'Por favor, espera...',
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      const objectValidateExist = {
        id_tlmk: this.detail_user.id_usuario,
        id_cliente: this.customer_id,
        id_bandera: this.id_flag_validation,
      };
      this.customersService
        .getValidacionCliente(objectValidateExist)
        .subscribe((res) => {
          console.log(res);
          Swal.close();
          console.log(this.id_flag_validation);
          if (
            res.responseValidacion[0].ID_BANDERA === this.id_flag_validation
          ) {
            this.setFlag(true);
            this.get_temporal();

            setTimeout(() => {
              this.validate_rows();
              this.get_disabled(this.rows);
            }, 1000);
          } else {
            Swal.fire({
              title: '',
              confirmButtonText: 'OK',
            });
            Swal.fire({
              title: 'Ventanas duplicadas',
              text: 'No puede proceder al paso 2 en esta ventana',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Aceptar',
            });
            const valor: any = {
              index: 0,
            };
            valor.index = 0;
            //     clearInterval(this.countdownInterval);
            //     console.log('el tiempo acabo')
            (<HTMLElement>(
              document.querySelectorAll('.mat-tab-label')[0]
            )).click();
            this.TabChange(valor);
          }
        });
    }
  }

  deletePromotionsToRow() {
    // this.rows = this.rows.filter((row:any)=> row.marca !== "MERCADOTECNIA");
    const rowsToDelete = [];
    for (let i = 0; i < this.rows.length; i++) {
      if (this.rows[i].marca === 'MERCADOTECNIA') {
        rowsToDelete.push(i);
      }
    }

    // Elimina los registros de this.rows
    for (let i = rowsToDelete.length - 1; i >= 0; i--) {
      this.rows.splice(rowsToDelete[i], 1);
    }

    this.get_total(this.rows);
  }
  get_disabled(rows: any) {
    if (rows.length > 0) {
      this.process_order = '';
    } else {
      this.process_order = 'disabled';
    }
  }

  upload_csv(event: any) {
    Swal.fire({
      title: 'Confirmación',
      text: '¿Desea cargar el pedido?, se perderan los artículos cargados actualmente.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        var files = event.target.files; // FileList object
        var file = files[0];
        var reader = new FileReader();
        reader.readAsText(file);

        reader.onload = (event: any) => {
          var csv = event.target.result; // Content of CSV file
          let allTextLines = csv.split(/\r\n|\n|\r/);
          //let allTextLines = csv.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
          let headers = allTextLines[0].split(',');
          let lines = [];

          for (let i = 0; i < allTextLines.length; i++) {
            let orderDetails: any = {};
            let data = allTextLines[i].split(',');
            if (data.length === headers.length) {
              for (let j = 0; j < data.length; j++) {
                orderDetails[headers[j]] = data[j];
              }

              lines.push(orderDetails);
            }
          }

          //console.log("Lines" + JSON.stringify(lines));
          this.full_order_with_csv(lines);
        };
      }
    });
  }

  full_order_with_csv(lines_out_filter: any) {
    const resetRowsLocal = () => {
      this.rows = [];
      OrdersService.set_rows(this.rows);
      this.orders[this.customer.IDCliente] = this.rows;
      //Cambio local a bd
      // localStorage.setItem('orders', JSON.stringify(this.orders));
      this.process_order = 'disabled';
      this.delete_all_temporal();
      this.reset_totals();
    };

    resetRowsLocal();
    this.rows = [];
    this.csv_rows = [];
    // console.log(lines_out_filter)
    this.CsvLoaded = 1;

    const lines = lines_out_filter.map((item, index) => {
      if (index !== 0) {
        return {
          Cantidad: item.Cantidad,
          ClaveDapesa: item.ClaveDapesa.toUpperCase(),
          UM: item.UM,
        };
      } else {
        return item;
      }
    });
    // console.log(lines)

    // lines.splice(0,1)
    let claves = lines.map((obj) => obj.ClaveDapesa);

    // claves.splice(0, 1)

    claves = Array.from(new Set(claves));
    let porc_pp = parseFloat(this.customer.PP) / 100;

    //let pos = i * 10
    var params: any = {
      idCliente: this.customer.IDCliente,
      idMaterial: claves.toString(),
      idMoneda: this.customer.IDMoneda,
      idOrganizacionVentas: this.customer.IDOrganizacionVentas,
      idArea: this.customer.IDSede,
      idJerarquiaCliente: this.customer.IDJerarquiaCliente,
      idCanalDistribucion: this.customer.IDCanalDistribucion,
      //"idPosicion": `${pos}`,
    };
    // console.log(claves.toString())
    this.productsService.get_all_detail(params).subscribe((data) => {
      let array = data['data']['articulos'];
      // console.log(array)

      const getClaveIndex = (obj) => claves.indexOf(obj.clave);
      array.sort((a, b) => {
        const indexA = getClaveIndex(a);
        const indexB = getClaveIndex(b);
        return indexA - indexB;
      });

      // console.log(array)

      let products = array.filter((obj, index, self) => {
        var objString = JSON.stringify(obj);
        return (
          index === self.findIndex((el) => JSON.stringify(el) === objString)
        );
      });
      const pMaterialesArray = [];
      // console.log(products)
      for (let i = 0; i < products.length; i++) {
        let csv_product = lines.filter(
          (obj) => obj.ClaveDapesa == products[i].clave
        );
        let existe = this.csv_rows.find(
          (item) => item.clave === products[i].clave
        );

        pMaterialesArray.push({
          Posicion: i,
          IDMaterial: products[i].clave,
          IDCategoria: products[i].IDCategoriaProducto,
          Marca: products[i].marca,
        });

        if (!existe) {
          // console.log(data["data"]["unidades"][products[i].clave])

          if (data['data']['unidades'][products[i].clave]) {
            products[i]['measurement_units'] = this.format_unidades(
              data['data']['unidades'][products[i].clave],
              products[i].clave
            );
          } else {
            products[i]['measurement_units'] = [];
          }

          products[i]['csv'] = 1;
          products[i]['request_amount'] = csv_product[0].Cantidad;
          products[i]['status_refresh'] = 'primary';
          products[i]['idMarca'] = products[i].Marca2;
          products[i]['idMaterial'] = products[i].clave;

          products[i]['precioEstandar'] = parseFloat(products[i].precio); //ws precio estandar
          products[i]['price_disc_standar'] = parseFloat(products[i].precio); //ws precio estandar
          products[i]['standard_price'] = parseFloat(products[i].precio); //ws precio estandar
          products[i]['descuentoEstandar'] = 0; //ws precio estandar
          products[i]['standard_discount'] = 0; //ws precio estandar
          products[i]['descuentoComercial'] = parseFloat(products[i].oculdesc);
          products[i]['comercial_discount'] = parseFloat(products[i].oculdesc);
          products[i]['comer_disc_porc'] =
            parseFloat(products[i].comercial_discount) / 100;
          products[i]['precioLista'] = parseFloat(products[i].precio);
          products[i]['price_disc_comer'] =
            products[i].precioEstandar -
            products[i].precioEstandar * products[i].comer_disc_porc;
          products[i]['price_disc_comer_pp'] =
            parseFloat(products[i].price_disc_comer) -
            parseFloat(products[i].price_disc_comer) * porc_pp;
          products[i]['iva'] = parseFloat(products[i].valor_iva);
          products[i]['iva_conv'] = parseFloat(products[i].iva) / 100;
          products[i]['mostrar'] = '2';
          // if(lines[i]){
          products[i]['multiplo_venta'] = this.get_min_multiplo_venta(
            products[i],
            lines[i].Cantidad
          );
          // }else{
          //   products[i]["multiplo_venta"] = this.get_min_multiplo_venta(products[i], 0)
          // }

          products[i]['multiploVenta'] = products[i].multiplo_venta;
          products[i] = this.um_convertion(
            products[i],
            products[i].unidad_medida_clave
          );
          products[i]['cantidadPiezas'] = products[i].total_pzas;

          if (products[i]['existencia'] < parseFloat(products[i].total_pzas)) {
            products[i]['total_row'] =
              parseFloat(products[i]['price_disc_comer']) *
              products[i]['existencia'];
          } else {
            products[i]['total_row'] =
              parseFloat(products[i]['price_disc_comer']) *
              parseFloat(products[i].total_pzas);
          }

          this.get_inventory(products[i]);
          // console.log(products[i])
          this.csv_rows.push(products[i]);
        }
      }

      // Descomentar promocionales
      const params_to_promocionales = {
        pMateriales: pMaterialesArray,
        pIDOrganizacionVentas: this.customer.IDOrganizacionVentas,
        pIDCanalDistribucion: this.customer.IDCanalDistribucion,
        pIDArea: this.customer.IDSede,
      };

      this.productsService
        .get_promocionales(params_to_promocionales)
        .subscribe((res) => {
          this.csv_rows.forEach((product: any) => {
            res.body.forEach((promo: any) => {
              if (product.clave === promo.IdMaterial) {
                product['promocion'] = true;
                product['banner'] = promo.Banner;
              }
            });
          });
        });

      this.rows = this.csv_rows;
      OrdersService.set_rows(this.rows);
      this.orders[this.customer.IDCliente] = this.rows;
      this.get_total(this.rows);

      //AGREGADO
      this.update_temporal_bloque(this.rows, 'insert_art');
      this.validate_rows(true);
      this.CsvLoaded = 2;

      // this.get_temporal()
      //................
      // this.validate_rows()

      $('.file_csv').val('');

      // if(this.csv_rows.length == (lines.length - 1) ){
      //   this.validate_rows()
      // }
    });
  }

  format_unidades(unidades: any, clave: any) {
    // let unidades_format = {}
    let all_unidades = [];
    for (let i = 0; i < unidades.length; i++) {
      let unidades_format = {};
      unidades_format['Cantidad'] = unidades[i][2];
      unidades_format['CantidadConversion'] = unidades[i][3];
      unidades_format['IDMaterial'] = clave;
      unidades_format['IDUnidadMedida'] = unidades[i][0];
      unidades_format['UnidadMedida'] = unidades[i][1];
      all_unidades.push(unidades_format);
    }

    // console.log(all_unidades)
    return all_unidades;
  }

  get_min_multiplo_venta(product: any, amount: any) {
    //multiplo_venta = minimo de venta
    if (Number(amount) <= Number(product.multiplo_venta)) {
      //product["multiplo_venta"] = amount
      product['multiplo_venta'] = product.multiplo_venta;
    } else {
      product['multiplo_venta'] = product.multiplo_venta;
    }
    return product.multiplo_venta;
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    let indexTemp = 0;
    this.dataSource.filteredData.map((item) => {
      item['indexTemp'] = indexTemp;
      indexTemp++;
    });
    this.articleSelected = -1;
    this.maxIndexSelect = this.dataSource.filteredData.length;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  pageEvents(event: any) {
    this.paginaInicio = event.pageIndex;
    this.paginaFin = event.pageIndex * this.pageSize;
    if (event.pageIndex > this.pageNo) {
      // Clicked on next button
    } else {
      // Clicked on previous button
    }
    // The code that you want to execute on clicking on next and previous buttons will be written here.
  }

  check_permissions() {
    if (
      ['7', '1', '61'].includes(this.detail_user.id_usuario) &&
      this.customer.IDJerarquiaCliente
    ) {
      this.copy_permissions = 1;
    } else {
      this.copy_permissions = 0;
    }
  }

  downloadCSV() {
    // console.log(this.orders)
    //ClaveDapesa   Cantidad   UM
    //clave         total_pzas
    // console.log(this.rows)
    const dataForDownload = [];
    this.rows.map((row) => {
      const tempRow = {
        ClaveDapesa: row.clave,
        Cantidad: parseInt(row.request_amount),
        UM: '',
      };
      dataForDownload.push(tempRow);
    });
    const csvData = Papa.unparse(dataForDownload, {
      // quotes: true, // Agrega comillas a los valores
      delimiter: ',', // Delimitador de campos
    });

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'plantilla_csv_pedido_actual.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}

@Component({
  selector: 'product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css'],
})
export class ProductDetailComponent {
  public panelOpenState = false;

  constructor(
    private newOrderComponent: NewOrderComponent,
    public pop_detail: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      clave: '';
      clasificacion: '';
      descripcion: '';
      CategoriaProducto: '';
      descripcion_2: '';
      marca: '';
      UnidadMedida: '';
      unidad_medida_clave: '';
      ClaveProveedor: '';
      existencia: '';
      familia: '';
      images: '';
      standard_price: '';
      precio: '';
      standard_discount: any;
      oculdesc: any;
      comercial_discount: any;
      price_disc_standar: any;
      price_disc_comer: any;
      request_amount: any;
      status_amount: any;
      equivalences: '';
      measurement_units: any;
      PP: any;
      armadora: any;
      descripcion_3: any;
      producto: any;
      promocion: any;
      banner: any;
    }
  ) {}

  get_product_detail(id: any, amount: any, index: any, add: any) {
    Swal.fire({
      title: 'Confirmación',
      text: '¿Desea agregar el producto al pedido?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.value) {
        this.newOrderComponent.get_product(id, amount, index, add, false, true);
      }
    });
  }
  openSliderPromotions(banners: any) {
    const dialogRef = this.pop_detail.open(ImageCarouselComponent, {
      data: {
        images: banners,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
