import { Component, OnInit } from '@angular/core';
import { CustomersService } from 'src/app/services/customers.service';
import { LoginService } from 'src/app/services/login.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public customers: any[] = [];
  public user: any;
  public detail_user: any;
  public isDataLoaded = 0;

  constructor(
    private customersService: CustomersService
  ) 
  {
    this.user = LoginService.get_user();
    this.detail_user = this.user["data"][0]
   }

  ngOnInit(): void {
    this.get_customers()
    OrdersService.set_products([]);
  }

  get_customers(){
    this.isDataLoaded = 1;
    this.customersService.getAll(this.detail_user.id_usuario, this.detail_user.id_tipo).subscribe(data => {
      this.customers = data["data"];
      this.isDataLoaded = 2;
      window.setTimeout( function (){ $('#customers-index').DataTable(
        {
          pagingType: 'full_numbers',
          language: {
            "processing": "Procesando",
            "lengthMenu": "Mostrar _MENU_ registros",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 registros del 0 de entradas",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar",
            "infoPostFix": "",
            "url": "",
            "zeroRecords": "No se encontraron resultados",
            "loadingRecords": "Cargando...",
            "paginate":{
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            }
    
            
            
          },
        }
      )}, 1000);
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    });
  }

}
